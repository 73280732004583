import { Environment } from '@pcid/web-sdk/build/definitions/environment';

// eslint-disable-next-line no-restricted-syntax
export const SPACE_ID = getMandatoryValue('NEXT_PUBLIC_SPACE_ID', process.env.NEXT_PUBLIC_SPACE_ID);
// eslint-disable-next-line no-restricted-syntax
export const ENVIRONMENT_ID = getMandatoryValue('NEXT_PUBLIC_ENVIRONMENT_ID', process.env.NEXT_PUBLIC_ENVIRONMENT_ID);
export const GOOGLE_MAPS_API_KEY = getMandatoryValue('GOOGLE_MAPS_API_KEY', process.env.GOOGLE_MAPS_API_KEY);

export const CONTENTFUL_DELIVERY_API_ACCESS_TOKEN = getMandatoryValue(
	'NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN',
	// eslint-disable-next-line no-restricted-syntax
	process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_API_ACCESS_TOKEN
);
export const CONTENTFUL_PREVIEW_API_ACCESS_TOKEN = getMandatoryValue(
	'NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_ACCESS_TOKEN',
	// eslint-disable-next-line no-restricted-syntax
	process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_ACCESS_TOKEN
);
export const CONTENTFUL_GRAPHQL_URL = `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${ENVIRONMENT_ID}`;
export const CONTENTFUL_PREVIEW_SECRET = getMandatoryValue(
	'NEXT_PUBLIC_CONTENTFUL_PREVIEW_SECRET',
	// eslint-disable-next-line no-restricted-syntax
	process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_SECRET
);
// eslint-disable-next-line no-restricted-syntax
export const GTM_ID = getMandatoryValue('NEXT_PUBLIC_GTM_ID', process.env.NEXT_PUBLIC_GTM_ID);
export const NEXT_PUBLIC_SENTRY_DSN = getMandatoryValue('SENTRY_DSN', process.env.SENTRY_DSN);
export const LAUNCHDARKLY_SDK_KEY = getMandatoryValue('LAUNCHDARKLY_SDK_KEY', process.env.LAUNCHDARKLY_SDK_KEY, '');

export const RegionCookie = 'region';
export const DefaultRegion = 'ON';
export const RegionHeader = 'x-region';

export const LocaleCookie = 'NEXT_LOCALE';
export const DefaultLocale = 'en';

export const CountryHeader = 'x-country';
export const DefaultCountry = 'CA';

export const AccessTokenCookie = 'access_token';
export const IdTokenCookie = 'id_token';

export const FallbackRegion = `${DefaultCountry}-${DefaultRegion}`;

export const OriginalHostHeader = 'x-original-host';

// eslint-disable-next-line no-restricted-syntax
export const IsPreview = getMandatoryValue('NEXT_PUBLIC_IS_PREVIEW', process.env.NEXT_PUBLIC_IS_PREVIEW) === 'true';

export const ProductionDomains: Array<string | null> = getMandatoryValue(
	'PRODUCTION_DOMAINS',
	process.env.PRODUCTION_DOMAINS,
	''
)
	.split(',')
	.filter(Boolean)
	.map((x) => x.trim());

export const IdcsBaseUrl = getMandatoryValue('IDCS_BASE_URL', process.env.IDCS_BASE_URL);
export const PcidBaseUrl = getMandatoryValue('PCID_BASE_URL', process.env.PCID_BASE_URL);

export const IdcsWellKnownUrl = `${IdcsBaseUrl}/.well-known/openid-configuration`;
export const IdcsClientId = getMandatoryValue('PCID_CLIENT_ID', process.env.PCID_CLIENT_ID);
export const IdcsTokenUrl = `${PcidBaseUrl}/token`;
export const IdcsClientSecretSecretName = getMandatoryValue(
	'IDCS_CLIENT_SECRET_SECRET_NAME',
	process.env.IDCS_CLIENT_SECRET_SECRET_NAME,
	'pchweb-pcid-client-secret'
);

export const RedisEndpoint = getMandatoryValue('REDIS_ENDPOINT', process.env.REDIS_ENDPOINT, 'redis://127.0.0.1:6379');
export const CacheExpiry = parseInt(getMandatoryValue('CACHE_EXPIRY', process.env.CACHE_EXPIRY, '600') as string, 10);
export const CacheInvalidationPsk = getMandatoryValue(
	'CONTENTFUL_CACHE_INVALIDATION_PSK',
	process.env.CONTENTFUL_CACHE_INVALIDATION_PSK
);
export const MidtierProfileBaseUrl = getMandatoryValue(
	'MID_TIER_PROFILE_BASE_URL',
	process.env.MID_TIER_PROFILE_BASE_URL
);
export const MidtierBookingManagementBaseUrl = getMandatoryValue(
	'MID_TIER_BOOKING_MANAGEMENT_BASE_URL',
	process.env.MID_TIER_BOOKING_MANAGEMENT_BASE_URL
);

export const MidtierBookingManagementPatientBaseUrl = getMandatoryValue(
	'MID_TIER_BOOKING_MANAGEMENT_PATIENT_BASE_URL',
	process.env.MID_TIER_BOOKING_MANAGEMENT_PATIENT_BASE_URL
);

export const SecretManagerProjectName = getMandatoryValue(
	'SECRET_MANAGER_PROJECT_NAME',
	process.env.SECRET_MANAGER_PROJECT_NAME
);
export const NextAuthSecretSecretName = getMandatoryValue(
	'NEXT_AUTH_SECRET_SECRET_NAME',
	process.env.NEXT_AUTH_SECRET_SECRET_NAME,
	'pchweb-nextauth-secret'
);
export const NextAuthUrl = getMandatoryValue('NEXTAUTH_URL', process.env.NEXTAUTH_URL);

export const CommitHash = getMandatoryValue('COMMIT_HASH', process.env.COMMIT_HASH);

// For SSR
export const SduiBffEndpointUrl = getMandatoryValue('SDUI_BFF_ENDPOINT_URL', process.env.SDUI_BFF_ENDPOINT_URL);
export const SduiBffApiKey = process.env.NEXT_PUBLIC_BFF_API_KEY ?? '';
// Used in ViewResolver component props for client side calls / refetching data to get configured BFF endpoint url in helios repo
// eslint-disable-next-line no-restricted-syntax
export const SduiBffEnv = getMandatoryValue('NEXT_PUBLIC_SDUI_BFF_ENV', process.env.NEXT_PUBLIC_SDUI_BFF_ENV);
export const SduiApiVersion = 'v2';
export const NodeEnv = process.env.NODE_ENV;
export const DeploymentEnv = parseEnvironment(getMandatoryValue('DEPLOYMENT_ENV', process.env.DEPLOYMENT_ENV));

// PC id My Account
export const MyAccountPcidUrl = getMandatoryValue('MY_ACCOUNT_PCID_URL', process.env.MY_ACCOUNT_PCID_URL);

// For Customer-API
export const CustomerAPIEndpointUrl = getMandatoryValue(
	'CUSTOMER_API_BASE_ENDPOINT_URL',
	process.env.CUSTOMER_API_BASE_ENDPOINT_URL,
	''
);

// Salesforce
export const SalesforceMCAuthBaseUrl = getMandatoryValue(
	'SALESFORCE_MC_AUTH_BASE_URL',
	process.env.SALESFORCE_MC_AUTH_BASE_URL
);
export const SalesforceMCRestBaseUrl = getMandatoryValue(
	'SALESFORCE_MC_REST_BASE_URL',
	process.env.SALESFORCE_MC_REST_BASE_URL
);
export const SalesforceMCFtpHost = getMandatoryValue('SALESFORCE_MC_FTP_HOST', process.env.SALESFORCE_MC_FTP_HOST);
export const SalesforceMCFtpUsername = getMandatoryValue(
	'SALESFORCE_MC_FTP_USERNAME',
	process.env.SALESFORCE_MC_FTP_USERNAME
);
export const SalesforceMCExternalKey = getMandatoryValue(
	'SALESFORCE_MC_EXTERNAL_KEY',
	process.env.SALESFORCE_MC_EXTERNAL_KEY
);
export const SalesforceMCClientId = getMandatoryValue('SALESFORCE_MC_CLIENT_ID', process.env.SALESFORCE_MC_CLIENT_ID);
export const SalesforceMCClientSecretName = getMandatoryValue(
	'SALESFORCE_MC_CLIENT_SECRET_NAME',
	process.env.SALESFORCE_MC_CLIENT_SECRET_NAME,
	'pchweb-salesforce-secret'
);
export const SalesforceMCSftpPasswordSecretName = getMandatoryValue(
	'SALESFORCE_MC_SFTP_PASSWORD_SECRET_NAME',
	process.env.SALESFORCE_MC_SFTP_PASSWORD_SECRET_NAME,
	'pchweb-salesforce-sftp-password'
);
export const SalesforceMCJobPsk = getMandatoryValue('SALESFORCE_MC_JOB_PSK', process.env.SALESFORCE_MC_JOB_PSK);

// CCM/One Trust
export const oneTrustScriptId = process.env.ONE_TRUST_SCRIPT_ID;

// mapping PSK header value to process.env PSK value
export const pskMapping = {
	'x-salesforce-mc-job-psk': SalesforceMCJobPsk || '',
	'x-cache-invalidation-psk': CacheInvalidationPsk || '',
};

// POCT
export const PoctServiceBaseUrl = getMandatoryValue('POCT_SERVICE_BASE_URL', process.env.POCT_SERVICE_BASE_URL);

export type PSKHeader = keyof typeof pskMapping;

function getMandatoryValue(key: string, value: string | undefined): string | undefined;
function getMandatoryValue(key: string, value: string | undefined, defaultValue: string): string;
function getMandatoryValue(key: string, value: string | undefined, defaultValue?: string | undefined) {
	const ret = value ?? defaultValue;
	if (
		!ret &&
		process.env.NODE_ENV !== 'test' &&
		!process.env.__haveWarnedAboutEnvironment &&
		typeof window === 'undefined'
	) {
		console.error(`Missing environment variable: ${key}`);
	}
	return ret;
}

function parseEnvironment(env?: string) {
	switch (env) {
		case 'prod':
			return Environment.PRODUCTION;
		default:
			return Environment.LOWER;
	}
}

export { getMandatoryValue };

process.env.__haveWarnedAboutEnvironment = 'yes';

function getLaunchDarklyOverrides(): Record<string, boolean> {
	try {
		return JSON.parse(process.env.LAUNCHDARKLY_OVERRIDES ?? '{}');
	} catch (ex) {
		console.warn('Could not parse LAUNCHDARKLY_OVERRIDES');
	}
	return {};
}

export const LaunchDarklyOverrides = getLaunchDarklyOverrides();
