import type { DamAsset } from '@ldfeplatform/drx-component-library.shared-types.types';
import { BannerIconText } from '@ldfeplatform/drx-component-library.ui.molecules.banner-icon-text';
import type { IconTextComponentProps } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component';
import type { BannerPosition } from '@ldfeplatform/drx-component-library.ui.templates.two-column-banner';
import type { FunctionComponent } from 'react';
import type { IconWithTextExtendedFieldsFragment, Maybe, PchBannerFieldsFragment } from '../contentful';
import { asIconPosition, asIconVariant, asTextVariant } from '../contentful';

export type BannerIconTextComponentProps = {
	banner: Maybe<PchBannerFieldsFragment>;
	loading: boolean;
};

// TODO: to be removed once the icon with text is removed from Contentful.
function isIconWithTextExtended(item: unknown): item is IconWithTextExtendedFieldsFragment {
	return (
		typeof item === 'object' && item !== null && '__typename' in item && item.__typename === 'IconWithTextExtended'
	);
}

export const BannerIconTextComponent: FunctionComponent<BannerIconTextComponentProps> = ({ banner, loading }) => {
	if (!banner) {
		return <></>;
	}
	return (
		<>
			<BannerIconText
				isLoading={loading}
				imageAspectRatio1by1={banner.imageAspectRatio1By1 as DamAsset}
				imageAspectRatio16by9={banner.imageAspectRatio16By9 as DamAsset}
				imagePosition={(banner.imagePosition as BannerPosition) ?? 'left'}
				iconTextComponents={
					banner.iconWithTextList?.iconWithTextItemsCollection?.items.filter(isIconWithTextExtended).map(
						(item): IconTextComponentProps => ({
							icon: item?.icon ?? '',
							text: item?.text ?? '',
							subtext: item?.subtext ?? '',
							iconPosition: asIconPosition(item?.iconPosition),
							iconVariant: asIconVariant(item?.iconVariant),
							textVariant: asTextVariant(item?.textVariant),
						})
					) ?? []
				}
			/>
		</>
	);
};
