import { z } from 'zod';

export const LoginCustomActionPayloadContract = z.object({
	returnUrl: z.string().optional(),
});

export type LoginCustomActionPayload = z.infer<typeof LoginCustomActionPayloadContract>;

export const SignupCustomActionPayloadContract = z.object({
	returnUrl: z.string().optional(),
});

export type SignupCustomActionPayload = z.infer<typeof SignupCustomActionPayloadContract>;

export const LogoutCustomActionPayloadContract = z.object({
	explicit: z.boolean(),
	returnUrl: z.string().optional(),
});

export type LogoutCustomActionPayload = z.infer<typeof LogoutCustomActionPayloadContract>;
