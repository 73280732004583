import { createEffect, createEvent, createStore, type EventPayload } from 'effector';
import { Router } from 'next/router';

type PushParameters = Parameters<InstanceType<typeof Router>['push']>;

export type PushPayload = {
	url: PushParameters[0];
	as?: PushParameters[1];
	options?: PushParameters[2];
};

export const push = createEvent<PushPayload>();

export const $route = createStore<string>('');
export const setRoute = createEvent<string>();

$route.on(setRoute, (_, next) => next);

export const changeLocationFx = createEffect(({ url }: PushPayload) => {
	if (typeof window !== 'undefined') {
		window.location.href = url.toString();
	}
});

export const pushPayload = (url: PushParameters[0], as?: PushParameters[1], options?: PushParameters[2]) =>
	({ url, as, options }) satisfies EventPayload<typeof push>;
