import { gql } from '@apollo/client';

export const getHomePageContentQuery = gql`
	query HomePageWithContent(
		$locale: String!
		$slug: String!
		$banner: String
		$region: String
		$preview: Boolean = false
		$isAuth: Boolean = false
	) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on HomepageTemplate {
						...HomepageTemplateFields
					}
				}
			}
		}
	}
`;

export const getGetTheAppPageContentQuery = gql`
	# eslint-disable-next-line @graphql-eslint/naming-convention
	query GetTheAppPageWithContent(
		$locale: String!
		$slug: String!
		$banner: String
		$region: String
		$preview: Boolean = false
	) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on GetTheAppTemplate {
						...GetTheAppTemplateFields
					}
				}
			}
		}
	}
`;

export const getProductPageContentQuery = gql`
	query ProductPageWithContent(
		$locale: String!
		$slug: String!
		$banner: String
		$region: String
		$preview: Boolean = false
	) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on ProductPageTemplate {
						...ProductPageTemplateFields
					}
				}
			}
		}
	}
`;

export const getLandingPageWithContentQuery = gql`
	query LandingPageWithContent(
		$locale: String!
		$slug: String!
		$preview: Boolean = false
		$region: String!
		$banner: String!
	) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on LandingPageTemplate {
						...LandingPageTemplateFields
					}
				}
			}
		}
	}
`;

export const getPchArticleTemplateQuery = gql`
	query PchArticleTemplate($locale: String!, $slug: String!, $preview: Boolean = false) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on PchArticleTemplate {
						...PchArticleTemplateFields
					}
				}
			}
		}
	}
`;

export const getPchMetricsTemplateQuery = gql`
	query PchMetricsTemplate(
		$locale: String!
		$slug: String!
		$preview: Boolean = false
		$banner: String
		$region: String
	) {
		pageCollection(preview: $preview, limit: 1, locale: $locale, where: { slug: $slug }) {
			items {
				content {
					... on PchMetricsTemplate {
						...PchMetricsTemplateFields
					}
				}
			}
		}
	}
`;
