import { gql } from '@apollo/client';

export const LandingPageTemplateFields = gql`
	fragment LandingPageTemplateFields on LandingPageTemplate {
		heroBannerListCollection(
			limit: 1
			where: {
				AND: [
					{ OR: [{ restrictionListBannerCollection_exists: false }, { restrictionListBanner: { banner: $banner } }] }
					{ OR: [{ restrictionListRegionCollection_exists: false }, { restrictionListRegion: { region: $region } }] }
				]
			}
			order: [title_DESC]
		) {
			items {
				...BannerFields
			}
		}
		featureBanner {
			...BannerFields
		}
		iconWithTextHeadingSubheading {
			...HeadingSubheadingFields
		}
		servicesIconWithTextHeadingSubheading {
			...HeadingSubheadingFields
		}
		servicesIconWithTextListCollection {
			items {
				...IconWithTextExtendedFields
			}
		}
		featureIconTextListCollection {
			items {
				...IconWithTextExtendedFields
			}
		}
		faqHeading {
			...HeadingSubheadingFields
		}
		faq {
			...FAQFields
		}
		references {
			json
		}
		bottomNavCardSection {
			...NavCardFields
		}
		bookAppointmentHeading
		bookAppointmentDescription
		bookAppointmentCta {
			...CallToActionFields
		}
	}
`;
