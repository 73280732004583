import type { UserProfile } from '../../services/midtier/user/__generated';
import type { DeleteSalesforceRecordPayload, SalesforceRecordPayload } from '../../services/salesforce';

export type UpsertSalesforceRecordAction = 'create' | 'login' | 'update';

export const upsertSalesforceRecord = async (payload: SalesforceRecordPayload | DeleteSalesforceRecordPayload) => {
	await fetch('/api/upsertSalesforceRecord', {
		method: 'PUT',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify(payload),
	});
};

export const transformProfileSalesforce = (
	action: UpsertSalesforceRecordAction,
	profile: UserProfile,
	createdTime?: string
): SalesforceRecordPayload => {
	const {
		language,
		firstName,
		lastName,
		preferredFirstName,
		dateOfBirth,
		phone,
		gender = [],
		sex,
		pronouns = [],
		termsOfService,
		privacyPolicy,
		marketingEmailConsent,
		leagueUserId,
		address,
		email,
	} = profile;
	return {
		language: language === 'fr-CA' ? 'FRENCH' : 'ENGLISH',
		firstName,
		lastName,
		preferredFirstName,
		dateOfBirth,
		phone,
		gender: gender.join(','),
		sex,
		pronouns: pronouns.join(','),
		termsOfService,
		privacyPolicy,
		marketingEmailConsent,
		leagueUserId,
		city: address?.city,
		province: address?.province,
		postalCode: address?.postalCode,
		country: address?.country,
		email: email,
		createdTime,
		...(action === 'login' && { lastLoginTime: new Date().toISOString() }),
		// marketingPushConsent
	};
};
