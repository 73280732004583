/**
 * Formats slug as a string. If slug is an array, joins elements with '/'.
 * @param {string | string[]} slug - A string or an array of strings to format.
 * @returns {string} The formatted string.
 */

export const normalizeRoute = (slug: string | string[] | undefined, defaultRoute?: string) => {
	if (!slug) {
		return defaultRoute ?? '';
	}
	return Array.isArray(slug) ? slug.join('/') : slug;
};
