import { createFactory, invoke } from '@withease/factories';
import { createEvent, createStore } from 'effector';
import { debug } from 'patronum';

export type AuthenticatedSession = {
	status: 'authenticated';
	accessToken: string;
	pcId: string;
};
export type UnauthenticatedSession = { status: 'unauthenticated' };
export type SessionStore = AuthenticatedSession | UnauthenticatedSession;

const ProfileManagerFactory = createFactory(() => {
	const $session = createStore<SessionStore>({ status: 'unauthenticated' });

	const setSessionData = createEvent<string | null>();

	const $profile = createStore({});
	const updateProfile = createEvent();

	$profile.on(updateProfile, (_, p) => p);

	$session.on(setSessionData, (_, payload) => {
		if (payload === null) {
			return { status: 'unauthenticated' };
		}

		return {
			status: 'authenticated',
			accessToken: payload,
			pcId: getSub(payload),
		};
	});

	return { $session, setSessionData, updateProfile, $profile };
});

function getSub(payload: string) {
	const data = JSON.parse(atob(payload.split('.')[1]));

	return data.sub;
}

export const ProfileManager = invoke(ProfileManagerFactory);
export const isAuthenticatedSession = (session: SessionStore): session is AuthenticatedSession =>
	session.status === 'authenticated';

debug({ trace: true }, ProfileManager);
