import { gql } from '@apollo/client';

export const getPageItemsQuery = gql`
	query PageItems($locale: String!, $slug: String!, $region: String, $banner: String, $preview: Boolean = false) {
		locationList(id: "4kUIPHICBYgBnR0E2lWLHt", locale: $locale) {
			provinces
		}
		pageCollection(
			preview: $preview
			limit: 1
			locale: $locale
			where: {
				AND: {
					slug: $slug
					OR: [{ bannerRestrictionCollection_exists: false }, { bannerRestriction: { banner: $banner } }]
				}
			}
		) {
			items {
				masthead {
					...MastheadFields
				}
				seoSettings {
					...SeoSettingsFields
				}
				footer {
					...FooterFields
				}
				content {
					__typename
				}
				analytics
				pageVariant
				slug
				disclaimerListCollection(
					where: {
						AND: [
							{ OR: [{ restrictionBannerCollection_exists: false }, { restrictionBanner: { banner: $banner } }] }
							{ OR: [{ restrictionRegionCollection_exists: false }, { restrictionRegion: { region: $region } }] }
						]
					}
				) {
					items {
						body {
							json
						}
					}
				}
			}
		}
	}
`;
