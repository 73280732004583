export const toggleOneTrust = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
	e.preventDefault();
	e.stopPropagation();

	if (
		'OneTrust' in window &&
		window.OneTrust instanceof Object &&
		'ToggleInfoDisplay' in window.OneTrust &&
		window.OneTrust.ToggleInfoDisplay instanceof Function
	) {
		window.OneTrust.ToggleInfoDisplay();
	}
};
