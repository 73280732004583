import { gql } from '@apollo/client';

export const MetricsPageTemplateFields = gql`
	fragment PchMetricsTemplateFields on PchMetricsTemplate {
		__typename
		onSiteTestsInfo {
			...OnSiteTestsInfoFields
		}
	}
`;
