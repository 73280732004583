import { invoke } from '@withease/factories';
import { StartAptBookingContract } from '../bookingFlow/contracts';
import { startAppointmentBooking } from '../bookingFlow/model';
import { showToast } from '../toast';
import { CustomActionFactory } from './customAction';

export const startAppointmentBookingCustomAction = invoke(() =>
	CustomActionFactory({
		contract: StartAptBookingContract,
		targetEvent: startAppointmentBooking,
		showToast,
	})
);
