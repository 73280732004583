import type { SessionContextParams } from '@/shared/session';
import { createContext, useContext } from 'react';

export const SessionContext = createContext<SessionContextParams>({ status: 'unauthenticated' });

/** @deprecated Use AccountManager instead */
export const useSessionContext = () => {
	const context = useContext(SessionContext);
	if (!context) {
		throw new Error(`useSessionContext must be used within <SessionContext.Provider>`);
	}

	return context;
};
