import { AccountManager, type Account, type AccountStatus } from '@/entities/account';
import { useUnit } from 'effector-react';
import { createContext, useContext, useMemo, type Dispatch, type PropsWithChildren } from 'react';

export type ProfileContextParams = {
	account?: Account;
	accountStatus: AccountStatus;
	setAccount: Dispatch<Account>;
};

export const ProfileContext = createContext<ProfileContextParams | null>(null);

export function ProfileContextProvider({ children }: PropsWithChildren) {
	const { $account: account, $accountStatus: accountStatus, setAccount } = useUnit(AccountManager);

	// * Memoize content. Only update if next and previous state differ
	const memoizedContext = useMemo(() => ({ account, accountStatus, setAccount }), [account, accountStatus, setAccount]);

	return <ProfileContext.Provider value={memoizedContext}>{children}</ProfileContext.Provider>;
}

/** @deprecated Use AccountManager instead */
export const useProfileContext = () => {
	const context = useContext(ProfileContext);

	if (!context) {
		throw new Error(`useProfileContext must be used within <ProfileContext.Provider>`);
	}

	return context;
};
