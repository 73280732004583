import type { SimpleMastheadProps } from '@ldfeplatform/drx-component-library.ui.atoms.simple-masthead';

export const NUMBERSTEPS = 3;

export const simpleMastheadProps: SimpleMastheadProps = {
	icon: {
		__typename: 'CallToAction',
		label: '',
		variant: 'primary',
		image: {
			asset: {
				url: 'https://dis-prod.assetful.loblaw.ca/content/dam/loblaw-companies-limited/creative-assets/digital-pharmacy/mock-data/PCH_logo.svg',
			},
			altText: 'PCH Icon',
		},
		linkUrl: '/',
		target: '_self',
		dataAnalytics: '',
	},
};
