import { invoke } from '@withease/factories';
import { z } from 'zod';
import { hideModal } from '../modal/model';
import { showToast } from '../toast';
import { CustomActionFactory } from './customAction';

export const closeModalCustomAction = invoke(() =>
	CustomActionFactory({
		targetEvent: hideModal,
		contract: z.null(),
		showToast: showToast,
	})
);
