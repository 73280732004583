/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * PCH APIs
 * PCH Midtier APIs for Account Sharing
<h><CHANGELOGS></h>
* Aug 25, 2023 - Initial version of APIs for Account Sharing<br/>
* Aug 31, 2023<br/>
  - Field "firstName" is made optional
  - Added fields createdAt, updatedAt
  - Added examples for source, destination, ISO8601DateTime<br/>
* Sep 06, 2023<br/>
  - Simplify consent - Add profile level and partner specific consent fields.
  - Change source field to an object<br/>
* Sep 08, 2023<br/>
  - Modify v2/user response for POST and GET
  - Add marketingEmailConsent and marketingPushConsent
* Sep 14, 2023<br/>
  - Update partnerConsents object
  - Add channel field
  - Add Version header field
* Oct 10, 2023<br/>
  - Update Address object to replace streetAddress with streetAddress1 and streetAddress1
  - Make channel mandatory
* Oct 12, 2023<br/>
  - Replace createdAt, updatedAt with channelCreatedTime, channelUpdatedTime resp.
  - Remove content-type
  - Make gender, pronoun as string array type fields
  - Add bannerName field to UserProfile
  - Add destinationCreatedTime, destinationUpdatedTime
  - Replace partnerUserId, partnerId with destinationPartnerUserId, destinationPartnerId resp.
  - Make source optional
  - Make profile mandatory on UpdatePCHUser
  - Fix typos in partnerConsents
* Oct 23, 2023<br/>
  - Update gender, pronouns fields
  - Fix typos
* Oct 27, 2023<br/>
  - Update channel and x-pch-client values
* Nov 6, 2023<br/>
  - Update securitySchemes scope to "pchss_accounts"
  - Remove UUID format restrictions from leagueUserId
* Dec 11, 2023<br/>
  - Added deletion APIs
* Jan 10, 2024<br/>
  - Change "email" field to optional
  - Add "partnerName" to "partnerConsents" Object
* Jan 19, 2024<br/>
  - Add X-Loblaw-getPCOMemberInfo, X-Loblaw-getPCOHouseholdPoints and X-Loblaw-getPCOIdentities to GET Header
  - Add idcsRequired to PATCH Header
* Jan 23, 2024<br/>
  - Add idcsRequired to DELETE Header
* Feb 14, 2024<br/>
  - include in response the markedToDelete field from CDS for GET Requests

 * OpenAPI spec version: 1.1.4
 */
import { userClient } from './client';
/**
 * Time out error message - generated by web server
 */
export type N504ErrorResponse = DefaultError;

/**
 * Service Unavailable - generated by web server
 */
export type N503ErrorResponse = DefaultError;

/**
 * Internal Server Error
 */
export type N500ErrorResponse = DefaultError;

/**
 * Resource Not Found
 */
export type N404ErrorResponse = DefaultError;

/**
 * The request failed validation. Access forbidden
 */
export type N403ErrorResponse = DefaultError;

/**
 * Unauthorized
 */
export type N401ErrorResponse = DefaultError;

/**
 * The request could not be processed due to bad syntax.
 */
export type N400ErrorResponse = DefaultError;

/**
 * Time out error message - generated by web server
 */
export type N504Error = DefaultError;

/**
 * Service Unavailable - generated by web server
 */
export type N503Error = DefaultError;

/**
 * Bad Gateway
 */
export type N502Error = DefaultError;

/**
 * Internal Server Error
 */
export type N500Error = DefaultError;

/**
 * Resource Not Found
 */
export type N404Error = DefaultError;

/**
 * The request failed validation. Access forbidden
 */
export type N403Error = DefaultError;

/**
 * Validation Error
 */
export type N402Error = DefaultError;

/**
 * Unauthorized
 */
export type N401Error = DefaultError;

/**
 * The request could not be processed due to bad syntax.
 */
export type N400Error = DefaultError;

/**
 * Language code mapping using ISO-639-3 standard
| ISO 639-3 Code  | language |
|-----------------|----------|
|	eng	|	English	|
|	fra	|	French	|
|	spa	|	Spanish	|
|	ara	|	Arabic	|
|	ben	|	Bengali	|
|	yue	|	Cantonese	|
|	hrv	|	Croatian	|
|	ces	|	Czech	|
|	dan	|	Danish	|
|	nld	|	Dutch	|
|	fin	|	Finnish	|
|	deu	|	German	|
|	hun	|	Hungarian	|
|	ita	|	Italian	|
|	jpn	|	Japanese	|
|	kor	|	Korean	|
|	cmn	|	Mandarin	|
|	nor	|	Norwegian	|
|	pus	|	Pashto	|
|	fas	|	Persian	|
|	tgl	|	Tagalog (Filipino)	|
|	pol	|	Polish	|
|	pan	|	Punjabi	|
|	ron	|	Romanian	|
|	rus	|	Russian	|
|	sol	|	Somalian	|
|	swe	|	Swedish	|
|	ukr	|	Ukrainian	|
|	rud	|	Urdu	|
|	vie	|	Vietnamese	|
|	por	|	Portuguese	|
|	tam	|	Tamil	|
|	heb	|	Hebrew	|
|	fas	|	Farsi	|
|	hin	|	Hindi	|
|	sqi	|	Albanian	|
|	hye	|	Armenian	|
|	bul	|	Bulgarian	|
|	guj	|	Gujarati	|
|	ell	|	Greek	|
|	jav	|	Javanese	|
|	khm	|	Khmer (Cambodian)	|
|	mal	|	Malayalam	|
|	nan	|	Min Nan	|
|	mar	|	Marathi	|
|	srp	|	Serbian	|
|	tel	|	Telugu	|
|	tur	|	Turkish	|
|	bos	|	Bosnian	|
|	mdk	|	Macedonian	|
|	afr	|	Afrikaans	|
|	slv	|	Slovenian	|
|	ase	|	American Sign Language (ASL)	|
|	tha	|	Thailand	|
 */
export type ISO6393LangCode = (typeof ISO6393LangCode)[keyof typeof ISO6393LangCode];

export const ISO6393LangCode = {
	eng: 'eng',
	fra: 'fra',
	spa: 'spa',
	ara: 'ara',
	ben: 'ben',
	yue: 'yue',
	hrv: 'hrv',
	ces: 'ces',
	dan: 'dan',
	nld: 'nld',
	fin: 'fin',
	deu: 'deu',
	hun: 'hun',
	ita: 'ita',
	jpn: 'jpn',
	kor: 'kor',
	cmn: 'cmn',
	nor: 'nor',
	pus: 'pus',
	fas: 'fas',
	tgl: 'tgl',
	pol: 'pol',
	pan: 'pan',
	ron: 'ron',
	rus: 'rus',
	sol: 'sol',
	swe: 'swe',
	ukr: 'ukr',
	rud: 'rud',
	vie: 'vie',
	por: 'por',
	tam: 'tam',
	heb: 'heb',
	hin: 'hin',
	sqi: 'sqi',
	hye: 'hye',
	bul: 'bul',
	guj: 'guj',
	ell: 'ell',
	jav: 'jav',
	khm: 'khm',
	mal: 'mal',
	nan: 'nan',
	mar: 'mar',
	srp: 'srp',
	tel: 'tel',
	tur: 'tur',
	bos: 'bos',
	mdk: 'mdk',
	afr: 'afr',
	slv: 'slv',
	ase: 'ase',
	tha: 'tha',
} as const;

export type PartnerConsentsItem = {
	/** Users consent to terms of service */
	tos: boolean;
	/** Partner id applicable for the consents. PartnerId values applicable below
*PartnerName|PartnerId*
MAPLE|CHC_PARTNER_0001
LIFEMARK|CHC_PARTNER_0002
MEDME|CHC_PARTNER_0004
 */
	partnerId: string;
	/** Partner Name applicable for the consents. PartnerName values applicable below
*PartnerName|PartnerId*
MAPLE|CHC_PARTNER_0001
LIFEMARK|CHC_PARTNER_0002
MEDME|CHC_PARTNER_0004
 */
	partnerName: string;
};

/**
 * Contains users consent information related to partners/subsystems
Reference for partner definitions https://confluence.lblw.cloud/display/PCH/Party+ID+Definitions
Only send partnerConsents if user has accepted their Terms of Service for the first time, or the user
has changed their Terms of Service
```json
{
  "partnerConsents": [
    {
      "tos": true,
      "partnerId": "CHC_PARTNER_0002",
      "partnerName": "LIFEMARK"
    },
    {
      "tos": true,
      "partnerId": "CHC_PARTNER_0004",
      "partnerName": "MEDME"
      "
    }
  ]
}

 */
export type PartnerConsents = PartnerConsentsItem[];

export interface Address {
	streetAddress1: string;
	streetAddress2?: string;
	city: string;
	province: string;
	postalCode: string;
	country?: string;
	/** Text representation of the address */
	text?: string;
}

/**
 * Channel info to identify source of update. Source channels should ignore this in the response.
 */
export type UpdateUserProfileChannel = (typeof UpdateUserProfileChannel)[keyof typeof UpdateUserProfileChannel];

export const UpdateUserProfileChannel = {
	WEBAPP: 'WEBAPP',
	MOBILE_APP: 'MOBILE_APP',
} as const;

/**
 * This is used for updating user Profile information
 */
export interface UpdateUserProfile {
	address?: Address;
	/** Channel info to identify source of update. Source channels should ignore this in the response. */
	channel: UpdateUserProfileChannel;
	/** communication language of the user - eng for English, fra for French */
	communicationLanguage?: ISO6393LangCode;
	consultLanguages?: ISO6393LangCode[];
	/** Date of birth of the user */
	dateOfBirth?: ISO8601Date;
	/** Email of the user */
	email?: string;
	/** Legal first name of the user */
	firstName?: string;
	gender?: string[];
	/** Language of the user */
	language?: string;
	/** Last name of the user */
	lastName?: string;
	/** League user id */
	leagueUserId?: string;
	/** Users consent to receive marketing emails */
	marketingEmailConsent?: boolean;
	/** Users consent to receive marketing push notification */
	marketingPushConsent?: boolean;
	/** Contains users consent information related to partners/subsystems */
	partnerConsents?: PartnerConsents;
	/** Phone number of the user */
	phone?: E164Phone;
	/** Preferred first name of the user */
	preferredFirstName?: string;
	/** Users consent to LCL privacy policy */
	privacyPolicy?: boolean;
	pronouns?: string[];
	/** Sex as entered by the user */
	sex?: string;
	/** Users consent to PCH terms of service */
	termsOfService?: boolean;
}

/**
 * Channel info for CDS/Helios to identify source. Source channels should ignore this in the response.
 */
export type UserProfileChannel = (typeof UserProfileChannel)[keyof typeof UserProfileChannel];

export const UserProfileChannel = {
	WEBAPP: 'WEBAPP',
	MOBILE_APP: 'MOBILE_APP',
} as const;

/**
 * Banner name of the application. This is required field as CCM mandates this field. CDS will send this field as is to CCM.

 */
export type UserProfileBannerName = (typeof UserProfileBannerName)[keyof typeof UserProfileBannerName];

export const UserProfileBannerName = {
	PC_HEALTH: 'PC_HEALTH',
} as const;

/**
 * This contains user Profile information
 */
export interface UserProfile {
	address: Address;
	/** Banner name of the application. This is required field as CCM mandates this field. CDS will send this field as is to CCM.
	 */
	bannerName: UserProfileBannerName;
	/** Channel info for CDS/Helios to identify source. Source channels should ignore this in the response. */
	channel: UserProfileChannel;
	/** communication language of the user - eng for English, fra for French */
	communicationLanguage?: ISO6393LangCode;
	consultLanguages?: ISO6393LangCode[];
	/** Date of birth of the user */
	dateOfBirth: ISO8601Date;
	/** Email of the user */
	email?: string;
	/** Legal first name of the user */
	firstName?: string;
	gender?: string[];
	/** Language of the user */
	language?: string;
	/** Last name of the user */
	lastName: string;
	/** League user id */
	leagueUserId?: string;
	/** Users consent to receive marketing emails */
	marketingEmailConsent?: boolean;
	/** Users consent to receive marketing push notification */
	marketingPushConsent?: boolean;
	/** Contains users consent information related to partners/subsystems */
	partnerConsents?: PartnerConsents;
	/** Phone number of the user */
	phone: E164Phone;
	/** Preferred first name of the user */
	preferredFirstName?: string;
	/** Users consent to LCL privacy policy */
	privacyPolicy: boolean;
	pronouns?: string[];
	/** Sex as entered by the user */
	sex: string;
	/** Users consent to PCH terms of service */
	termsOfService: boolean;
}

/**
 * PartnerUser object which contains relevant partnerUserId, partnerId and partnerName
*PartnerName|PartnerId*

MAPLE|CHC_PARTNER_0001
LIFEMARK|CHC_PARTNER_0002
LEAGUE|CHC_PARTNER_0003
MEDME|CHC_PARTNER_0004
DRX|CHC_PARTNER_0008
CDS|CHC_PARTNER_0009

 */
export interface PartnerUser {
	/** Unique user id assigned by the subsystem e.g. League user id, Maple user id */
	partnerUserId?: string;
	/** Static subsystem id assigned by Midtier, e.g. DRx is assigned as CHC_PARTNER_0008 */
	partnerId: string;
	/** User friendly name for the subsystem e.g DRx */
	partnerName?: string;
}

/**
 * PCH Profile object
 */
export interface UpdatePCHUser {
	/** Contains information pertaining to the source system that initiates user creation
```json
{
  "partnerUserId": "f81d4fae-7dec-11d0-a765-00a0c81e6bf5",
  "partnerId": "CHC_PARTNER_0003",
  "partnerName": "LEAGUE"
}
 */
	source?: PartnerUser;
	/** Contains information pertaining to the destination system that stores user information
```json
{
  "partnerUserId": "f81d4fae-8dec-22d0-a865-00a0c81e6bf5",
  "partnerId": "CHC_PARTNER_0009",
  "partnerName": "CDS"
}
 */
	destination: PartnerUser[];
	/** Contains user's profile information */
	profile: UpdateUserProfile;
	/** Profile creation timestamp of the user as recorded by the source channel(app or web) */
	channelCreatedTime?: ISO8601DateTime;
	/** Profile updation timestamp of the user as recorded by the source channel(app or web) */
	channelUpdatedTime?: ISO8601DateTime;
}

/**
 * PCH User Profile creation response
 */
export interface PCHUserResponse {
	/** PCID of the user */
	pcId: string;
	/** Attribute of PCOMemberInfo */
	colleague?: boolean;
	/** Attribute of PCOMemberInfo */
	pcOptimumMember?: boolean;
	/** Attribute of PCOMemberInfo */
	pcInsider?: boolean;
	/** Attribute of PCOMemberInfo */
	loyaltyMemberId?: string;
	/** Attribute of PCOMemberInfo */
	hasLoyaltyAccount?: boolean;
	/** Attribute of PCOMemberInfo */
	walletId?: string;
	/** Attribute of PCOHouseholdPoints */
	balance?: number;
	/** Attribute of PCOHouseholdPoints */
	dollarsRedeemable?: number;
	/** Attribute of PCOHouseholdPoints */
	dollarsRedeemedLifetime?: number;
	/** Attribute of PCOIdentities */
	ecommerceIdentifier?: string;
	/** Indicates if PCH USER Account deletion is in progress */
	markedToDelete?: boolean;
	/** Contains user's profile information */
	profile: UserProfile;
	/** Profile creation timestamp of the user as recorded by the destination(e.g. CDS) */
	destinationCreatedTime?: ISO8601DateTime;
	/** Profile updation timestamp of the user as recorded by the destination channel(e.g. CDS) */
	destinationUpdatedTime?: ISO8601DateTime;
}

/**
 * PCH User Profile creation request
 */
export interface PCHUserRequest {
	/** PCID of the user */
	pcId: string;
	/** Contains information pertaining to the source system that initiates user creation
```json
{
  "partnerUserId": "f81d4fae-7dec-11d0-a765-00a0c81e6bf5",
  "partnerId": "CHC_PARTNER_0003",
  "partnerName": "LEAGUE"
}
 */
	source?: PartnerUser;
	/** Contains information pertaining to the destination system that stores user information
```json
{
  "partnerUserId": "f81d4fae-8dec-22d0-a865-00a0c81e6bf5",
  "partnerId": "CHC_PARTNER_0009",
  "partnerName": "CDS"
}
 */
	destination: PartnerUser[];
	/** Contains user's profile information */
	profile: UserProfile;
	/** Profile creation timestamp of the user as recorded by the source channel(app or web) */
	channelCreatedTime?: ISO8601DateTime;
	/** Profile updation timestamp of the user as recorded by the source channel(app or web) */
	channelUpdatedTime?: ISO8601DateTime;
}

export type MessageHeaderToParty = {
	/** LCL assigned partner ID */
	id: string;
	/** LCL partner name */
	name: string;
	/** Issuing party name */
	idIssuedBy: string;
};

export type MessageHeaderTo = {
	party: MessageHeaderToParty;
};

export type MessageHeaderFromParty = {
	/** LCL assigned partner ID */
	id: string;
	/** LCL partner name */
	name: string;
	/** Issuing party name */
	idIssuedBy: string;
};

export type MessageHeaderFrom = {
	party: MessageHeaderFromParty;
};

/**
 * operationMode indicates if test or live processing is required. Valid values are TEST or PRODUCTION. This value is mandatory.
 */
export type MessageHeaderOperationMode = (typeof MessageHeaderOperationMode)[keyof typeof MessageHeaderOperationMode];

export const MessageHeaderOperationMode = {
	TEST: 'TEST',
	PRODUCTION: 'PRODUCTION',
} as const;

/**
 * "$schema": http://json-schema.org/draft-06/schema#
LCL API Message Header JSON schema
BASE64 ENCODE  the JSON data and add it to the header parameter X-LCL-API-MessageHeader
```json
{
   "$schema": "http://json-schema.org/draft-06/schema#",
   "description": "LCL API Message Header JSON schema\nBASE64 the JSON data and add it to the header parameter X-LCL-API-MessageHeader",
   "type": "object",
   "required": [
       "traceabilityId",
       "correlationId",
       "messageId",
       "operationMode",
       "version",
       "messageTimestamp",
       "from",
       "to"
   ],
   "properties": {
       "traceabilityId": {
           "type": "string",
           "format": "uuid",
           "maxLength": 60,
           "example": "f81d4fae-7dec-11d0-a765-00a0c91e6bf2",
           "description": "A unique identifier to allow tracing of all participating vendor system components and messages when processing a particular request. Generally used for end to end troubleshooting purpose.Uniquely generated by request initiator for each request and expected same value to be exchanged through participating components and echoed back in the responses. e.g. f81d4fae-7dec-11d0-a765-00a0c91e6bf2"
       },
       "correlationId": {
           "type": "string",
           "format": "uuid",
           "maxLength": 60,
           "example": "f81d4fae-7dec-11d0-a765-00a0c91e6bf3",
           "description": "Unique identifier to allow association of a response with a request in a manner independent of the underlying transmission and/or composition method. Uniquely generated by service consumer and provider should echo back the request's value using the response's CorrelationID field.Unlike TraceabilityID. correlationID generally will be different for each hop(one pair of service consumer and provider component) in the end to end processing flow.  eg. f81d4fae-7dec-11d0-a765-00a0c91e6bf3"
       },
       "messageId": {
           "type": "string",
           "format": "uuid",
           "maxLength": 60,
           "example": "f81d4fae-7dec-11d0-a765-00a0c91e6bf4",
           "description": "Unique identifier to identify each message instance.  Response should it’s own message id different from request.   eg. f81d4fae-7dec-11d0-a765-00a0c91e6bf4"
       },
       "sessionId": {
           "type": "string",
           "maxLength": 255,
           "description": "represents each unique user login session. This is required/useful to identify all previous API calls belong to same  user session when analyzing why a particular API call fails.    "
       },
       "userId": {
           "type": "string",
           "maxLength": 60,
           "example": "f81d4fae-7dec-11d0-a765-00a0c91e6bf5",
           "description": "represent the end user/customer that the technical system is  sending this request on behalf of"
       },
       "operationMode": {
           "type": "string",
           "maxLength": 20,
           "enum": [
               "TEST",
               "PRODUCTION"
           ],
           "example": "PRODUCTION",
           "description": "operationMode indicates if test or live processing is required.  Valid values are TEST or PRODUCTION. This value is mandatory."
       },
       "version": {
           "type": "string",
           "maxLength": 10,
           "example": "1.0.1",
           "description": "Version of the API JSON data format"
       },
       "messageTimestamp": {
           "type": "string",
           "format": "date-time",
           "example": "2021-07-29T10:16:52.650+00:00",
           "description": "Timestamp of creation date of the message e.g. 2014-02-01T10:16:52.650+00:00 see [rfc3339 section-5.6](https://tools.ietf.org/html/rfc3339#section-5.6) "
       },
       "from": {
           "type": "object",
           "properties": {
               "party": {
                   "type": "object",
                   "properties": {
                       "id": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "2",
                           "description": "LCL assigned partner ID"
                       },
                       "name": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "LEAGUE",
                           "description": "LCL partner name"
                       },
                       "idIssuedBy": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "LCL",
                           "description": "Issuing party name"
                       }
                   },
                   "required": [
                       "id",
                       "name",
                       "idIssuedBy"
                   ]
               }
           },
           "required": [
               "party"
           ]
       },
       "to": {
           "type": "object",
           "properties": {
               "party": {
                   "type": "object",
                   "properties": {
                       "id": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "1",
                           "description": "LCL assigned partner ID"
                       },
                       "name": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "LCL-PCH-MIDTIER",
                           "description": "LCL partner name"
                       },
                       "idIssuedBy": {
                           "type": "string",
                           "maxLength": 60,
                           "example": "LCL",
                           "description": "Issuing party name"
                       }
                   },
                   "required": [
                       "id",
                       "name",
                       "idIssuedBy"
                   ]
               }
           },
           "required": [
               "party"
           ]
       }
   }
}
 */
export interface MessageHeader {
	/** A unique identifier to allow tracing of all participating vendor system components and messages when processing a particular request. Generally used for end to end troubleshooting purpose.Uniquely generated by request initiator for each request and expected same value to be exchanged through participating components and echoed back in the responses. e.g. f81d4fae-7dec-11d0-a765-00a0c91e6bf2 */
	traceabilityId: string;
	/** Unique identifier to allow association of a response with a request in a manner independent of the underlying transmission and/or composition method. Uniquely generated by service consumer and provider should echo back the request's value using the response's CorrelationID field.Unlike TraceabilityID. correlationID generally will be different for each hop(one pair of service consumer and provider component) in the end to end processing flow. eg. f81d4fae-7dec-11d0-a765-00a0c91e6bf3 */
	correlationId: string;
	/** Unique identifier to identify each message instance. Response should it’s own message id different from request. eg. f81d4fae-7dec-11d0-a765-00a0c91e6bf4 */
	messageId: string;
	/** represents each unique user login session. This is required/useful to identify all previous API calls belong to same user session when analyzing why a particular API call fails. */
	sessionId?: string;
	/** represent the end user/customer that the technical system is sending this request on behalf of */
	userId?: string;
	/** operationMode indicates if test or live processing is required. Valid values are TEST or PRODUCTION. This value is mandatory. */
	operationMode: MessageHeaderOperationMode;
	/** Version of the API JSON data format */
	version: string;
	/** Timestamp of creation date of the message e.g. 2014-02-01T10:16:52.650+00:00 see [rfc3339 section-5.6](https://tools.ietf.org/html/rfc3339#section-5.6) */
	messageTimestamp: string;
	from: MessageHeaderFrom;
	to: MessageHeaderTo;
}

export interface ErrorObj {
	/** error number */
	code: number;
	/** short description to unique identifying the error code above */
	shortDesc: string;
	/** detail description for the error */
	message: string;
}

export type DefaultError = ErrorObj[];

export type E164Phone = string;

/**
 * CA-ON - provide a list
 */
export type ISO3166Location = string;

/**
 * ISO 8601 Date <br/> pattern: '^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$'
 */
export type ISO8601Date = string;

/**
 * Timestamp of creation date of the message e.g. 2014-02-01T10:16:52.650+00:00
 */
export type ISO8601DateTime = string;

/**
 * Timestamp of creation date of the message e.g. </br>
10:16:52-5:00 HH24:MM:SS-TZD<br/>
HH24 = two digits of an hour (00 through 23, AM/PM not included) </br>
mm = two digits of a minute (00 through 59) </br>
ss = two digits of a second (00 through 59) </br>
TZD = time zone designator (Z or +hh:mm or -hh:mm), the + or - values indicate how far ahead or behind a time zone is from the UTC (Coordinated Universal Time) zone.</br>
 */
export type ISO8601TimeWithTimeZone = string;

/**
 * Timestamp of creation date of the message e.g.</br> 10:16:52 HH24:MM:SS<br/> pattern: '^\d[0-2]\d:[0-5]\d:[0-5]\d$'</br> see [rfc3339 section-5.6](https://tools.ietf.org/html/rfc3339#section-5.6) <br/>
 */
export type ISO8601Time = string;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * POST PCH profile information to CDS/Helios
 * @summary POST PCH profile information to CDS/Helios
 */
export const postV2User = (pCHUserRequest: PCHUserRequest, options?: SecondParameter<typeof userClient>) => {
	return userClient<PCHUserResponse>(
		{ url: `/v2/user`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: pCHUserRequest },
		options
	);
};

/**
 * Update PCH profile information to CDS/Helios
 * @summary Update PCH profile information to CDS/Helios
 */
export const patchV2User = (updatePCHUser: UpdatePCHUser, options?: SecondParameter<typeof userClient>) => {
	return userClient<PCHUserResponse>(
		{ url: `/v2/user`, method: 'patch', headers: { 'Content-Type': 'application/json' }, data: updatePCHUser },
		options
	);
};

/**
 * GET PCH profile information from CDS/Helios
 * @summary GET PCH profile information from CDS/Helios
 */
export const getV2User = (options?: SecondParameter<typeof userClient>) => {
	return userClient<PCHUserResponse>({ url: `/v2/user`, method: 'get' }, options);
};

/**
 * DELETE PCH profile information from CDS/Helios
 * @summary DELETE PCH profile information from CDS/Helios
 */
export const deleteV2User = (options?: SecondParameter<typeof userClient>) => {
	return userClient<void>({ url: `/v2/user`, method: 'delete' }, options);
};

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export type PostV2UserResult = NonNullable<Awaited<ReturnType<typeof postV2User>>>;
export type PatchV2UserResult = NonNullable<Awaited<ReturnType<typeof patchV2User>>>;
export type GetV2UserResult = NonNullable<Awaited<ReturnType<typeof getV2User>>>;
export type DeleteV2UserResult = NonNullable<Awaited<ReturnType<typeof deleteV2User>>>;
