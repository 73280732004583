import { gql } from '@apollo/client';

export const GetSpaceMeta = gql`
	query SpaceMeta {
		spaceMetaCollection(limit: 1) {
			items {
				migrationVersion
			}
		}
	}
`;
