import { Box, useTheme } from '@chakra-ui/react';
import { type Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { Description } from '@ldfeplatform/drx-component-library.ui.atoms.typography.description';
import { Title } from '@ldfeplatform/drx-component-library.ui.atoms.typography.title';
import type { FunctionComponent } from 'react';
import type { HeadingSubheadingFieldsFragment, Maybe } from '../../contentful';

export type HeadingSpecsProps = {
	px?: { base: number | string; md?: number | string; lg?: number | string };
	pt?: { base: number | string; md?: number | string; lg?: number | string };
	pb?: { base: number | string; md?: number | string; lg?: number | string };
	mb?: { base: number | string; md?: number | string; lg?: number | string };
	maxWidth?: { base: number | string; md?: number | string; lg?: number | string };
	textAlign?: { base: 'center' };
	margin?: { base: '0 auto' };
};

export type SubHeadingSpecsProps = {
	px?: { base: number | string; md?: number | string; lg?: number | string };
	pt?: { base: number | string; md?: number | string; lg?: number | string };
	pb?: { base: number | string; md?: number | string; lg?: number | string };
	mb?: { base: number | string; md?: number | string; lg?: number | string };
	maxWidth?: { base: number | string; md?: number | string; lg?: number | string };
	textAlign?: { base: 'center' };
};

export type HeadingSubheadingComponentProps = {
	heading?: Maybe<HeadingSubheadingFieldsFragment>;
	headSpecs?: HeadingSpecsProps;
	subHeadSpecs?: SubHeadingSpecsProps;
	center?: boolean;
};

export const HeadingSubheadingComponent: FunctionComponent<HeadingSubheadingComponentProps> = ({
	center = false,
	heading,
	headSpecs,
	subHeadSpecs,
}) => {
	const theme = useTheme<Tokens>();

	if (!heading) {
		return <></>;
	}

	return (
		<Box data-testid="heading-subheading-component" {...(center ? { center } : {})}>
			{heading.heading && (
				<Box
					px={headSpecs?.px || { base: 8, '2xl': 32 }}
					pt={headSpecs?.pt || { base: 16, md: 20 }}
					pb={headSpecs?.pb || { base: 5 }}
					{...headSpecs}
				>
					<Title {...theme.headingSubheading.subHeading}>{heading.heading}</Title>
				</Box>
			)}
			{heading.subheading && (
				<Box px={{ base: 8, '2xl': 32 }} pb={subHeadSpecs?.pb || { base: 5 }} {...subHeadSpecs}>
					<Description>{heading.subheading}</Description>
				</Box>
			)}
		</Box>
	);
};
