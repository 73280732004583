import { ProfileManager, isAuthenticatedSession, type SessionStore } from '@/features/profile';
import { errorToToast, showToast } from '@/features/toast';
import { testingInternals } from '@/shared/testingInternals';
import { createFactory, invoke } from '@withease/factories';
import { createEffect, createEvent, createStore, sample, split, type Store } from 'effector';
import { debug } from 'patronum';
import { createBooking, type GetAuthenticatedUrlFxInput } from './api';
import type {
	AbandonMessage,
	CloseMessage,
	EnterMessage,
	ErrorMessage,
	IFrameEvent,
	SuccessMessage,
} from './contracts';

export type BookingIframeManagerSettings = {
	session: Store<SessionStore>;
	showToast: typeof showToast;
};

export type FlowSucceededPayload = {
	appointmentId: string;
	province: string;
	type: string;
	event: 'success';
	eventType: 'walkin' | 'booking';
};

export const BookingIframeManagerFactory = createFactory(({ session, showToast }: BookingIframeManagerSettings) => {
	/* Stores */
	const $iframeUrl = createStore<URL | null>(null);

	/* Events */
	const getAuthenticatedUrl = createEvent<GetAuthenticatedUrlFxInput>();
	const hideIframe = createEvent();
	const flowEntered = createEvent<EnterMessage>();
	const flowAbandoned = createEvent<AbandonMessage>();
	const flowErrored = createEvent<ErrorMessage>();
	const flowSucceeded = createEvent<SuccessMessage>();
	const flowClosed = createEvent<CloseMessage>();

	const handleIframeEvent = createEvent<IFrameEvent>();

	/* Effects */
	const getAuthenticatedUrlFx = createEffect(createBooking);

	/* Business Logic */

	sample({
		clock: getAuthenticatedUrlFx.failData, // When MidTier call fails
		fn: errorToToast, // Convert Error to Toast
		target: showToast, // Forward to showToast
	});

	sample({
		clock: getAuthenticatedUrlFx.fail,
		fn: (e) => ({ event: 'error' as const, details: e.error.message }),
		target: flowErrored,
	});

	sample({
		clock: getAuthenticatedUrl, // When getAuthenticatedUrl gets trigered
		source: session, // Grab session data
		filter: isAuthenticatedSession, // Check if we are authenticated
		fn: (_session, parameters) => parameters, // Pass parameters from the event
		target: getAuthenticatedUrlFx, // Invoke effect with these parameters
	});

	// When we get the URL from midier - update the store
	$iframeUrl.on(getAuthenticatedUrlFx.doneData, (_, params) => params);

	/* IFrame Events */

	split({
		// Only route events if the iframe is active
		source: sample({
			clock: handleIframeEvent,
			source: $iframeUrl,
			filter: (iframeUrl) => !!iframeUrl,
			fn: (_, e) => e,
		}),
		match: (event) => event.event,
		cases: {
			abandon: flowAbandoned,
			close: flowClosed,
			enter: flowEntered,
			error: flowErrored,
			success: flowSucceeded,
		},
	});

	// Hide iframe if any of those happen
	$iframeUrl.reset([hideIframe, flowAbandoned, flowClosed, flowSucceeded, flowErrored]);

	/* Public API */
	return {
		/** This store holds the url of the iframe */
		$iframeUrl,
		/** This event is triggered if the customer abandons the flow by pressing "Cancel" */
		flowAbandoned,
		/** This event is triggered when the flow had completed */
		flowClosed,
		/** This event is triggered when the iframe is first displayed */
		flowEntered,
		/** This event is triggered if the iframe has encountered an error */
		flowErrored,
		/** This event is triggered when an appointment is booked successfully */
		flowSucceeded,
		/** Trigger this event to begin the iframe flow */
		getAuthenticatedUrl,
		/** Trigger this event to handle postMessage events from the iframe */
		handleIframeEvent,
		/** Trigger this event to hide the iframe */
		hideIframe,
		[testingInternals]: {
			getAuthenticatedUrlFx,
		},
	};
});

export const BookingIframeManager = invoke(BookingIframeManagerFactory, {
	session: ProfileManager.$session,
	showToast,
});

export type BookingIframeModel = typeof BookingIframeManager;

debug({ trace: true }, BookingIframeManager);
