import { gql } from '@apollo/client';

export const PCHBannerFragment = gql`
	fragment PCHBannerFields on PchBannerIconText {
		bannerPosition
		imagePosition
		imageAspectRatio16By9 {
			...DamAssetFields
		}
		imageAspectRatio1By1 {
			...DamAssetFields
		}
		backgroundColor
		actionRowsCollection {
			items {
				title
			}
		}
		iconWithTextList {
			iconWithTextItemsCollection {
				items {
					... on IconWithTextExtended {
						...IconWithTextExtendedFields
					}
				}
			}
		}
	}
`;
