import { gql } from '@apollo/client';

export const DamAssetFragment = gql`
	fragment DamAssetFields on DamAsset {
		title
		isAltTextRequired
		altText
		asset
	}
`;
