import { useUnit } from 'effector-react';
import { useEffect, type FunctionComponent } from 'react';
import { createPortal } from 'react-dom';

import { IframeMessageContract } from './contracts';
import { BookingIframeManager } from './model';

const HideOverflow = () => {
	useEffect(() => {
		const currentOverflow = window.document.documentElement.style.overflowY;
		window.document.documentElement.style.overflowY = 'hidden';
		return () => {
			window.document.documentElement.style.overflowY = currentOverflow;
		};
	}, []);

	return <></>;
};

export const BookingIframe: FunctionComponent = () => {
	const { $iframeUrl: iframeUrl, handleIframeEvent } = useUnit(BookingIframeManager);

	useEffect(() => {
		window.handleIframeEvent = handleIframeEvent;
		window.BookingIframeManager = BookingIframeManager;

		if (!iframeUrl) {
			return;
		}

		console.log('Setting up iFrame event handling');
		const handler = (e: MessageEvent) => {
			if (!iframeUrl || !e.isTrusted || !e.origin.endsWith('medmeapp.com')) {
				return;
			}

			try {
				const message = IframeMessageContract.parse(JSON.parse(e.data));

				handleIframeEvent(message);
			} catch (ex) {
				// show some toast here or sentry or something
			}
		};
		window.addEventListener('message', handler);
		return () => {
			console.log('Cleaning up iFrame event handling');
			window.removeEventListener('message', handler);
		};
	}, [iframeUrl, handleIframeEvent]);

	if (!iframeUrl) {
		return <></>;
	}

	return (
		<>
			<HideOverflow />
			{createPortal(
				<>
					<iframe
						style={{
							position: 'fixed',
							width: '100vw',
							height: '100vh',
							zIndex: 99999,
							background: 'white',
						}}
						src={iframeUrl.toString()}
						onLoad={(e) => console.log('loaded', e)}
						onError={(e) => console.info('error', e)}
					></iframe>
				</>,
				document.getElementById('iframe-outlet')!
			)}
		</>
	);
};
