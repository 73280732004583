import { z } from 'zod';
import { PartnerIdContract, PartnerNameContract } from '../consent';

export const StartAptBookingContract = z.object({
	partnerId: PartnerIdContract,
	partnerName: PartnerNameContract,
	specialtyId: z.string().optional(),
	serviceId: z.string().optional(),
	utm: z
		.object({
			source: z.string(),
			medium: z.string(),
			campaign: z.string(),
		})
		.optional(),
});

export type StartAptBookingParams = z.infer<typeof StartAptBookingContract>;
