import { Box, Flex } from '@chakra-ui/react';
import type { CallToAction } from '@ldfeplatform/drx-component-library.shared-types.types';
import { ActionRow } from '@ldfeplatform/drx-component-library.ui.molecules.action-row';
import type { FunctionComponent } from 'react';
import type { CallToActionFieldsFragment, Maybe } from '../../contentful';

export type CTACollectionComponentProps = {
	items?: Maybe<CallToActionFieldsFragment>[];
};

export const CTACollectionComponent: FunctionComponent<CTACollectionComponentProps> = ({ items = [] }) => {
	const row1 = (items || []) as CallToAction[];
	return (
		<>
			<Flex flexDir="column" alignItems="center" borderRadius="1rem">
				<Box>
					<ActionRow actionRow={{ row1 }}></ActionRow>
				</Box>
			</Flex>
		</>
	);
};
