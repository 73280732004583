import { gql } from '@apollo/client';

export const FooterFragment = gql`
	fragment FooterFields on Footer {
		copyright
		copyrightDetail
		languageToggleLink
		languageToggleText
		backToTopLink
		backToTopText
		languageToggleLinkDataAnalytics
		backToTopTextDataAnalytics
		footerLinksCollection(limit: 10) {
			items {
				...NavigationItemFields
			}
		}
	}
`;
