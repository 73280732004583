import { z } from 'zod';
import type { Nominal } from '../../types';

export type PartnerName = Nominal<string, 'PartnerName'>;
export type PartnerId = Nominal<string, 'PartnerId'>;

export const PartnerIdContract: z.Schema<PartnerId> = z.any().refine(z.string().parse);
export const PartnerNameContract: z.Schema<PartnerName> = z.any().refine(z.string().parse);

export const GiveConsentPayloadContract = z.object({
	tos: z.boolean(),
	partnerId: PartnerIdContract,
	partnerName: PartnerNameContract,
});

export const PartnerConsentsContract = z.array(GiveConsentPayloadContract);

export type Consent = z.infer<typeof GiveConsentPayloadContract>;
