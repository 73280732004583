import type {
	GetTheAppPageWithContentQuery,
	HomePageWithContentQuery,
	LandingPageWithContentQuery,
	PageContent,
	PchArticleTemplateQuery,
	PchMetricsTemplateQuery,
	ProductPageWithContentQuery,
} from './__generate__';

import type { IconVariant, TextVariant } from '@ldfeplatform/drx-component-library.shared-types.types';
import type { IconPosition } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component/dist/icon-text-component';

export const vaxcareSlug = 'vaxcare';
export const fastpassSlug = 'FASTPASS';
export const dmrPchSlug = 'dmr';
export const dmrSdmSlug = 'dmr-sdm';

export type PageContentType = PageContent['__typename'];

type QueryBase = {
	__typename?: 'Query' | undefined;
	pageCollection: {
		__typename?: 'PageCollection' | undefined;
		items: Array<{
			__typename?: 'Page';
			content: Omit<PageContent, 'sys' | 'contentfulMetadata' | 'linkedFrom' | '_id'> | null;
		} | null> | null;
	} | null;
};

type ExtractContent<Q extends QueryBase, C extends PageContentType> = Extract<
	NonNullable<NonNullable<NonNullable<Q['pageCollection']>['items']>[number]>['content'],
	{ __typename?: C }
>;

export type GetTheAppPageWithContent = ExtractContent<GetTheAppPageWithContentQuery, 'GetTheAppTemplate'>;
export type HomepageWithContent = ExtractContent<HomePageWithContentQuery, 'HomepageTemplate'>;
export type LandingPageWithContent = ExtractContent<LandingPageWithContentQuery, 'LandingPageTemplate'>;
export type PchArticlePageWithContent = ExtractContent<PchArticleTemplateQuery, 'PchArticleTemplate'>;
export type ProductPageWithContent = ExtractContent<ProductPageWithContentQuery, 'ProductPageTemplate'>;
export type MetricsPageWithContent = ExtractContent<PchMetricsTemplateQuery, 'PchMetricsTemplate'>;

export type PageWithContent =
	| GetTheAppPageWithContent
	| HomepageWithContent
	| LandingPageWithContent
	| PchArticlePageWithContent
	| ProductPageWithContent
	| MetricsPageWithContent;

export function asIconPosition(iconPosition: unknown): IconPosition | undefined {
	switch (iconPosition) {
		case 'top':
			return 'top';
		case 'left':
			return 'left';
		case 'topLeft':
			return 'topLeft';
		default:
			return undefined;
	}
}
export function asIconVariant(iconVariant: unknown): IconVariant | undefined {
	switch (iconVariant) {
		case 'square':
			return 'square';
		case 'rounded':
			return 'rounded';
		default:
			return undefined;
	}
}
export function asTextVariant(textVariant: unknown): TextVariant | undefined {
	switch (textVariant) {
		case 'plain':
			return 'plain';
		case 'markdown':
			return 'markdown';
		default:
			return undefined;
	}
}
