import { DefaultLocale } from '@/shared/config';
import type { ModalProps } from '@ldfeplatform/drx-component-library.ui.molecules.modal';
import { combine, createEffect, createEvent, createStore, sample } from 'effector';
import router from 'next/router';
import { debug } from 'patronum';
import { showToast } from '../toast';
import { client } from './../../../src/contentful';
import {
	ModalContentDocument,
	type ModalContentQuery,
	type ModalContentQueryVariables,
} from './../../../src/contentful/__generate__';

export type ModalIdentifier =
	| 'account-creation-success-modal'
	| 'vaxcare-error-modal'
	| 'vaxcare-pickup-not-available-modal'
	| 'claim-used-modal'
	| 'claim-found-modal'
	| 'claim-none-modal'
	| 'delete-account-confirm-modal'
	| string;

export type TemplateProps = {
	isMarkdownModal?: boolean;
	ctaOrientation?: ModalProps['ctaOrientation'];
};

export type ShowModalEventArgs = {
	identifier: string;
	templateProps: TemplateProps;
	templateVariables: Record<string, {}>;
};

// stores content from contentful
export const $contentCache = createStore<Record<string, ModalContentQuery>>({});
// stores modal properties need to be passed for SDUI
export const $templateProps = createStore<TemplateProps>({ isMarkdownModal: false, ctaOrientation: 'horizontal' });
// stores dynamic variables need to be replaced in Modal
export const $templateVariables = createStore<Record<string, {}>>({});

// stores for modal visibility
export const showModal = createEvent<ShowModalEventArgs>();
export const hideModal = createEvent<null>();
export const onModalHidden = createEvent();

export const $isVisible = createStore(false).on(showModal, () => true);
$isVisible.reset([hideModal, onModalHidden]);

// Store for modal identifier
export const $identifier = createStore<ModalIdentifier | null>(null);

// Effect to call contentful to modal content
export const loadModalContentFx = createEffect(async (identifier: ModalIdentifier) => {
	const variables = { locale: router.locale ?? DefaultLocale, identifier };
	const { data } = await client.query<ModalContentQuery, ModalContentQueryVariables>({
		query: ModalContentDocument,
		variables,
		fetchPolicy: 'no-cache',
	});
	if (!data.modalCollection?.items.length) {
		throw new Error(`Modal ${identifier} not found`);
	}
	return { identifier, data };
});

sample({
	clock: loadModalContentFx.doneData,
	source: [$identifier, $contentCache] as const,
	fn: ([id, cache], { identifier, data }) => {
		if (!id) return cache;
		return { ...cache, [identifier]: data };
	},
	target: $contentCache,
});

// If the contentful request fails - display an error toast
sample({
	clock: loadModalContentFx.fail,
	fn({ error }) {
		return { isSuccess: false, message: error.message };
	},
	target: showToast,
});

// Notify when modal loading fails
export const modalLoadingFailed = sample({
	clock: loadModalContentFx.fail,
	fn({ params }) {
		return params;
	},
});

// Sample to load content if not cached
sample({
	clock: showModal,
	source: $contentCache,
	filter: (cache, { identifier }) => !cache[identifier],
	fn: (_, { identifier }) => identifier,
	target: loadModalContentFx,
});

// Sample to update template variables on showModal
sample({
	clock: showModal,
	fn: ({ templateVariables }) => templateVariables,
	target: $templateVariables,
});

// Sample to update identifier on showModal
sample({
	clock: showModal,
	fn: ({ identifier }) => identifier,
	target: $identifier,
});

// Sample to update props on showModal
sample({
	clock: showModal,
	fn: ({ templateProps }) => templateProps,
	target: $templateProps,
});

// Combine stores (content and templateVariables) to get current modal state
export const $currentModal = combine(
	$contentCache,
	$identifier,
	$templateProps,
	$templateVariables,
	(contentCache, identifier, templateProps, templateVariables) => {
		if (!identifier) return null;
		return {
			data: contentCache[identifier],
			templateProps,
			templateVariables,
		};
	}
);

debug({ trace: true }, { showModal });
