import { gql } from '@apollo/client';

export const FeatureCardFragment = gql`
	fragment FeatureCardFields on Card {
		image {
			...DamAssetFields
		}
		badge
		heading
		body
		ctaCollection {
			items {
				...CallToActionFields
			}
		}
	}
`;
