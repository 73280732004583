import { browserLogger as logger } from '@/shared/instrumentation/browserLogger';

export const validateAvailabilityForBooking = async (CustomerAPIEndpointUrl: string, key: string) => {
	const path = `${CustomerAPIEndpointUrl}/api/v1/vaxcare/availability/?key=${key}`;
	const request: RequestInit = {
		method: 'GET',
		headers: {
			'x-drx-client': 'system',
		},
	};

	try {
		return await fetch(path, request);
	} catch (e) {
		logger.error(e);
	}
};

export type StoreData = {
	id?: number;
	phoneNumber?: string;
	storeId?: number;
	storeName?: string;
	bannerId?: string;
};

export const vaxcareConfirmAppointment = async (
	CustomerAPIEndpointUrl: string,
	key: string,
	data: string,
	locale: string
) => {
	const path = `${CustomerAPIEndpointUrl}/api/v1/vaxcare/appointment/confirm?key=${key}`;

	const requestObj = {
		...JSON.parse(data),
		channelType: 'WEB',
		locale,
	};

	try {
		return await fetch(path, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'x-drx-client': 'system',
			},
			body: JSON.stringify(requestObj),
		});
	} catch (e) {
		console.error(e);
	}
};
