import { RouterBridge } from '@/entities/routerBridge';
import { ApplicationLoader } from '@/features/application';
import { BookingIframe } from '@/features/bookingIframe';
import { ModalOutlet } from '@/features/modal';
import { ToastOutlet } from '@/features/toast';
import { SessionContext } from '@/shared/clientSession';
import { EnvironmentContext } from '@/shared/environment';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { pchTheme, sdmTheme } from '@ldfeplatform/drx-component-library.themes.theme';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { client } from '../src/contentful';
import { type BasicPageProps } from '../src/contentful/ssr';
import '../styles/globals.css';
import '../wdyr';

function App({
	Component,
	pageProps: { sessionContext, banner, environment, ...pageProps },
}: AppProps<BasicPageProps>) {
	const theme = banner === 'SDM' ? sdmTheme : pchTheme;
	if (!sessionContext) {
		console.warn('session data was not passed to the static props');
		sessionContext = { status: 'unauthenticated' };
	}
	if (!environment) {
		console.warn('environment data was not passed to the static props');
		environment = {};
	}
	return (
		<>
			<ChakraProvider theme={theme}>
				<ApplicationLoader session={sessionContext} environment={environment} />
				<ModalOutlet />
				<BookingIframe />
				<ToastOutlet />
				<RouterBridge />
				<ApolloProvider client={client}>
					<SessionContext.Provider value={sessionContext}>
						<EnvironmentContext.Provider value={environment}>
							<Component {...pageProps} />
						</EnvironmentContext.Provider>
					</SessionContext.Provider>
				</ApolloProvider>
			</ChakraProvider>
		</>
	);
}

export default appWithTranslation(App);
