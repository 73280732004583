import { AspectRatio, Box, Container, Flex, useTheme } from '@chakra-ui/react';
import { AccordionList } from '@ldfeplatform/drx-component-library.ui.molecules.accordion-list';
import { IconTextComponent } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component';
import { TwoColumnBanner } from '@ldfeplatform/drx-component-library.ui.templates.two-column-banner';
import type { FunctionComponent } from 'react';

import type {
	BannerWithAccordionFieldsFragment,
	DamAssetFieldsFragment,
	HeadingSubheadingFieldsFragment,
	IconWithTextExtendedFieldsFragment,
	Maybe,
	ProductPageWithContent,
} from '../contentful';

import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { ResponsiveImage } from '@ldfeplatform/drx-component-library.ui.atoms.responsive-image';
import {
	Banner,
	CardComponent,
	CTACollectionComponent,
	FAQComponent,
	GetTheApp,
	HeadingSubheadingComponent,
	NavCardComponent,
	Notice,
	PharmacyListComponent,
} from '../component';
import { asIconPosition, asIconVariant, asTextVariant } from '../contentful';

export type ProductPageTemplateProps = {
	loading?: boolean;
	content: ProductPageWithContent;
};

// TODO: to be removed once the icon with text is removed from Contentful.
function isIconWithTextExtended(item: unknown): item is IconWithTextExtendedFieldsFragment {
	return (
		typeof item === 'object' && item !== null && '__typename' in item && item.__typename === 'IconWithTextExtended'
	);
}

export const ProductPageTemplate: FunctionComponent<ProductPageTemplateProps> = ({ content, loading = false }) => {
	const theme = useTheme<Tokens>();

	const cardListLayout = 'row';

	const cardContentLayout = 'column';

	if (loading) {
		return <></>;
	}

	if (content?.__typename !== 'ProductPageTemplate') {
		return <Notice text="Invalid content returned from CMS" />;
	}

	const {
		heroBannerListCollection,

		logoGrid,
		logoGridCtAsCollection,

		faqHeadingSubheading,
		faq,

		bannerWithAccordionCollection,

		featureCardsHeading,
		featureCardsCollection,
		featureCardsCtAsCollection,

		bannerWithList1X1Image,
		bannerWithList16X9Image,
		bannerWithListHeading,
		bannerWithListIconWithTextCollection,

		navCardsHeading,
		navCardsCollection,

		getTheApp,
	} = content;
	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				{heroBannerListCollection?.items[0] && (
					<Banner content={heroBannerListCollection?.items[0]} type="hero" loading={loading} />
				)}
				<PharmacyListComponent logoGrid={logoGrid} />
				<CTACollectionComponent items={logoGridCtAsCollection?.items} />
				<Box bg={theme.colors.greyLight}>
					<FAQComponent faq={faq} heading={faqHeadingSubheading} />
				</Box>
				{bannerWithAccordionCollection?.items[0] && renderBannerWithAccordion(bannerWithAccordionCollection?.items[0])}
				{featureCardsCollection?.items && featureCardsCollection.items.length > 0 && (
					<Box pt={40} pb={20} bg={theme.colors.greyLight}>
						<HeadingSubheadingComponent heading={featureCardsHeading} />
						<CardComponent cards={featureCardsCollection?.items} />
						<CTACollectionComponent items={featureCardsCtAsCollection?.items} />
					</Box>
				)}
				{renderBannerWithList(
					bannerWithListHeading,
					bannerWithList1X1Image,
					bannerWithList16X9Image,
					bannerWithListIconWithTextCollection?.items?.filter(isIconWithTextExtended)
				)}
				<NavCardComponent
					cardListLayout={cardListLayout}
					cardContentLayout={cardContentLayout}
					heading={navCardsHeading}
					items={navCardsCollection?.items}
				/>
			</Container>
			<GetTheApp content={getTheApp} />
		</>
	);
};

function renderBannerWithAccordion(bannerWithAccordion: Maybe<BannerWithAccordionFieldsFragment>) {
	const heading = bannerWithAccordion?.bannerWithAccordionHeading;
	const ctas = bannerWithAccordion?.bannerWithAccordionCtAsCollection?.items[0]?.callToActionListCollection?.items;
	const accordions = bannerWithAccordion?.bannerWithAccordionAccordionCollection?.items ?? [];
	const backgroundColor = bannerWithAccordion?.backgroundColor.value;

	const textContent = (
		<Box pr={{ lg: 16 }}>
			<HeadingSubheadingComponent heading={heading} />
			<CTACollectionComponent items={ctas} />
		</Box>
	);
	const accordionList = accordions.map((item) => ({
		heading: item?.heading ?? '',
		body: item?.body ?? '',
		imageData: {
			src: item?.image?.asset?.url ?? '',
			alt: item?.image?.altText ?? '',
		},
	}));

	const showAccordionImages = accordionList.some((items) => items.imageData.src);

	const accordionAsContent = (
		<Box mt={16} mb={20}>
			<AccordionList accordionList={accordionList} showImage={showAccordionImages} />
		</Box>
	);
	return (
		<Box mx="auto" px={{ base: 4, md: 8 }} bg={backgroundColor}>
			<TwoColumnBanner contentOne={textContent} contentTwo={accordionAsContent} reverseOrder={false} />
		</Box>
	);
}

function renderBannerWithList(
	heading: Maybe<HeadingSubheadingFieldsFragment>,
	squareImage: Maybe<DamAssetFieldsFragment>,
	wideImage: Maybe<DamAssetFieldsFragment>,
	iconsWithText: Maybe<IconWithTextExtendedFieldsFragment>[] = []
) {
	if (!iconsWithText?.length) {
		return;
	}
	const headSpecs = {
		pt: { base: 10, md: 20 },
		pb: { base: 5 },
	};
	const subHeadSpecs = {
		pb: { base: 9 },
	};

	const textContent = (
		<>
			<HeadingSubheadingComponent heading={heading} headSpecs={headSpecs} subHeadSpecs={subHeadSpecs} />
			<Flex flexDir="column" gap={10} px={{ base: 8, '2xl': 32 }}>
				{iconsWithText.map((ic) => (
					<IconTextComponent
						key={ic?.text}
						text={ic?.text ?? ''}
						subtext={ic?.subtext ?? ''}
						icon={ic?.icon ?? ''}
						iconPosition={asIconPosition(ic?.iconPosition)}
						iconVariant={asIconVariant(ic?.iconVariant)}
						textVariant={asTextVariant(ic?.textVariant)}
					/>
				))}
			</Flex>
		</>
	);
	const imageContent = (
		<>
			<AspectRatio
				maxW={{ lg: '688px' }}
				maxH={{ lg: '500px' }}
				ratio={{
					base: 16 / 9,
					lg: 1,
					'2xl': 16 / 9,
				}}
				height="100%"
				overflow="hidden"
			>
				<ResponsiveImage
					src={{
						base: wideImage?.asset?.url,
						lg: squareImage?.asset?.url,
						'2xl': wideImage?.asset?.url,
					}}
					alt={{
						base: wideImage?.altText,
						lg: squareImage?.altText,
						'2xl': wideImage?.asset?.url,
					}}
					loading={'lazy'}
					fetchpriority={'auto'}
				/>
			</AspectRatio>
		</>
	);
	return <TwoColumnBanner contentOne={textContent} contentTwo={imageContent} contentTwoPosition="left" />;
}
