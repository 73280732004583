export const LAYER_NAME = 'analyticsLayer';
export const FORM = 'form';

// events
export const COMPONENT_CLEAR = 'component_clear';
export const EXCEPTION_CLEAR = 'exception_clear';
export const USER_STATE = 'user_state';
export const CREATE_PROFILE = 'create-profile';

// user state
export const ANONYMOUS = 'anonymous';
export const LOGGED_IN = 'logged_in';
export const NOT_LOGGED_IN = 'not_logged_in';
export const REGISTERED = 'registered';
export const GUEST = 'unknown';

// shopping banner
export const BANNER_PCH = 'pch';

//start event
export const MYPROFILE_EDIT_PERSONAL_INFO = 'myprofile_edit_personal_info';
export const MYPROFILE_EDIT_CONTACT_INFO = 'myprofile_edit_contact_info';

// exception types
export const EXCEPTION_TYPE_SUCCESS = 'success-notification';
export const EXCEPTION_TYPE_ERROR = 'error';
export const EXCEPTION_TYPE_WARNING = 'warning';

// flows
export const UPDATE_PROFILE_FLOW = 'update_profile';
export const CREATE_PROFILE_FLOW = 'create_profile';

// exception messages
export const CREATE_PROFILE_SUCCESS = 'Profile successfully created.';
export const CREATE_PROFILE_FAILED = 'Profile creation failed.';

//CTA
export const BANNER = 'banner';
export const VIEW_MORE = 'view-more';
export const VIEW_LESS = 'view-less';
export const CTA_CLICK = 'cta-click';
export const CLICK = 'click';
export const GET_STARTED = 'get-started';
