import { useSessionContext } from '@/shared/clientSession';
import { useEnvironment } from '@/shared/environment';
import {
	EventName as EventNameEnum,
	getDataLayerState,
	type Page,
	type User,
} from '@sdm/analytics-helper-library-pchweb';
import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { useProfileContext } from '../ProfileContext';
import { ANONYMOUS, BANNER_PCH, GUEST, LOGGED_IN, NOT_LOGGED_IN, REGISTERED, USER_STATE } from './snowplowConstants';
import type { EventName, EventParamsMap, SnowplowContextParams } from './snowplowTypes';
import { addClearEventParams, loadSnowplow, updateAnalyticsLayer } from './snowplowUtils';

export const SnowplowContext = createContext<SnowplowContextParams | null>(null);

export const SnowplowProvider = ({ children }: PropsWithChildren) => {
	const { accountStatus } = useProfileContext();
	const environment = useEnvironment();
	const { isSnowplowEnabled } = environment;

	const session = useSessionContext();
	const pcid = session.status === 'authenticated' ? session.pcid : null;

	const track = useCallback(
		<T extends EventName>(event: T, params: EventParamsMap[T]): Promise<void> | undefined => {
			if (isSnowplowEnabled) {
				return new Promise((resolve) => {
					loadSnowplow().then(() => {
						resolve(updateAnalyticsLayer(event, addClearEventParams(event, params)));
					});
				});
			}
		},
		[isSnowplowEnabled]
	);

	const pageView = useCallback(
		(params: Page) => {
			// before any page view call, ensure user context setup is done

			// if current analytics data layer does not have any user context OR its `accountStatus` does not match,
			// then make a track call and update `user_state` for snowplow tracking
			const userStateInAnalyticsLayer = getDataLayerState()?.user as User;
			if (!userStateInAnalyticsLayer || userStateInAnalyticsLayer?.accountStatus !== accountStatus) {
				const validAccount = accountStatus === 'fetched';
				const { status } = session;
				track(USER_STATE, {
					user: {
						pcid_id: status === 'authenticated' && pcid ? pcid : ANONYMOUS,
						pco_wallet_id: null, // @Todo: check how to get wallet id
						login_status: status === 'authenticated' ? LOGGED_IN : NOT_LOGGED_IN,
						registration_status: validAccount ? REGISTERED : GUEST,
						accountStatus,
					},
				});
			}

			return track(EventNameEnum.PageView, {
				page: params,
				shopping_experience: { banner: BANNER_PCH },
			});
		},
		[session, accountStatus, track, pcid]
	);
	// Memoize content. Only update if next and previous state differ
	const memoizedContext = useMemo(() => ({ pageView, track }), [pageView, track]);

	return <SnowplowContext.Provider value={memoizedContext}>{children}</SnowplowContext.Provider>;
};

export const useSnowplow = () => {
	const context = useContext(SnowplowContext);
	if (!context) {
		throw new Error(`useSnowplow must be used within <SnowplowContext.Provider>`);
	}
	return context;
};
