export function getSiteType(): string | undefined {
	const ChakraBreakpoints = {
		md: 768,
		xl: 1280,
	};
	if (typeof window === 'object') {
		const width = document.documentElement.clientWidth;
		if (width < ChakraBreakpoints.md) {
			return 'mobile';
		} else if (width >= ChakraBreakpoints.md && width < ChakraBreakpoints.xl) {
			return 'tablet';
		} else {
			return 'desktop';
		}
	}
}
