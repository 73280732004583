import { useRouter } from 'next/router';
import { useEffect, type FunctionComponent } from 'react';
import { push, setRoute } from './model';

export const RouterBridge: FunctionComponent = () => {
	const router = useRouter();

	useEffect(() => {
		const subscription = push.watch(({ url, as, options }) => router.push(url, as, options));

		return () => {
			subscription.unsubscribe();
		};
	}, [router]);

	useEffect(() => {
		setRoute(router.asPath);
	}, [router.asPath]);

	return <></>;
};
