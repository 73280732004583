import { gql } from '@apollo/client';

export const LogoListFragment = gql`
	fragment LogoListFields on PharmacyList {
		heading
		subHeading
		disclaimers
		expandLabel
		collapseLabel
		logosAboveFold
		logosCollection {
			items {
				...DamAssetFields
			}
		}
		sys {
			id
		}
	}
`;
