import { DefaultCountry, FallbackRegion, RegionCookie } from '@/shared/config';
import { getCookie, setCookie } from 'cookies-next';
import { createEvent, createStore } from 'effector';
import { useUnit } from 'effector-react';
import { useRouter } from 'next/router';
import React, {
	useContext,
	useEffect,
	useMemo,
	useState,
	type Dispatch,
	type PropsWithChildren,
	type SetStateAction,
} from 'react';

export type GeolocationContextParams = {
	geoLocation: string;
	setGeolocation: Dispatch<SetStateAction<string>>;
	provinces: string[];
	setProvinces: Dispatch<SetStateAction<string[]>>;
};

export const $region = createStore<string>(FallbackRegion, { skipVoid: false });
const setRegion = createEvent<string>();
$region.on(setRegion, (_, n) => n);

export const GeolocationContext = React.createContext<GeolocationContextParams | null>(null);

export const GeolocationProvider = ({ children }: PropsWithChildren) => {
	const region = getCookie(RegionCookie) ?? FallbackRegion;
	const [geoLocation, _setGeolocation] = useState(region);

	const [provinces, setProvinces] = useState<string[]>([]);

	const router = useRouter();
	const _setRegion = useUnit(setRegion);

	useEffect(() => {
		_setRegion(region ?? FallbackRegion);
	}, [region, _setRegion]);

	const providerValue = useMemo(() => {
		const setGeolocation: typeof _setGeolocation = (value) => {
			const newRegion = `${DefaultCountry}-${value}`;
			setCookie(RegionCookie, newRegion);
			router.replace(router.asPath);
			return _setGeolocation(newRegion);
		};

		return { geoLocation, setGeolocation, provinces, setProvinces };
	}, [geoLocation, provinces, router]);

	return <GeolocationContext.Provider value={providerValue}>{children}</GeolocationContext.Provider>;
};

/** @deprecated use $location instead */
export const useGeolocationContext = () => {
	const context = useContext(GeolocationContext);

	if (!context) {
		throw new Error(`useGeolocationProvider must be used within <GeolocationContext.Provider>`);
	}

	return context;
};
