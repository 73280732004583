import { gql } from '@apollo/client';

export const IconWithTextExtendedFields = gql`
	fragment IconWithTextExtendedFields on IconWithTextExtended {
		title
		icon
		text
		subtext
		iconPosition
		textVariant
		iconVariant
		image {
			...DamAssetFields
		}
	}
`;
