import { type AuthUrlResponseV2 } from '../../services/midtier/booking-management/__generated';
import type { CreateAppointmentRequest } from '../../types/booking';

export type GetAuthenticatedUrlFxInput = {
	partnerId: string;
	locale: string;
	province: string;
	specialtyId?: string;
	serviceId?: string;
	utm?: {
		campaign: string;
		medium: string;
		source: string;
	};
};

export const createBooking = async (params: GetAuthenticatedUrlFxInput) => {
	const payload: CreateAppointmentRequest = params;
	const response = await fetch('/api/booking/createAppointment/', {
		method: 'post',
		body: JSON.stringify(payload),
		headers: { 'Content-Type': 'application/json' },
	});

	if (!response.ok) {
		throw new Error(await response.text());
	}

	const data: AuthUrlResponseV2 = await response.json();
	const url = new URL(data.url);
	url.searchParams.append('webview', 'true');
	if (params.utm) {
		url.searchParams.append('utm_campaign', params.utm.campaign);
		url.searchParams.append('utm_medium', params.utm.medium);
		url.searchParams.append('utm_source', params.utm.source);
	}
	return url;
};
