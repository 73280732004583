import { gql } from '@apollo/client';
export const FaqFragment = gql`
	fragment FAQFields on Faq {
		title
		faqCta {
			...CallToActionFields
		}
		accordionListCollection {
			items {
				image {
					...DamAssetFields
				}
				heading
				body
			}
		}
	}
`;
