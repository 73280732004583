export * from './accountCreation';
export * from './banner';
export * from './BannerIconTextComponent';
export * from './card-component';
export * from './common';
export * from './commonHoc';
export * from './FAQComponent';
export * from './getTheApp';
export * from './NavCardComponent';
export * from './notice';
export * from './pageLoader';
export * from './PharmacyList';
export * from './seo';
// export * from './ViewResolverDigitalHealth';
