import { gql } from '@apollo/client';

export const GetTheAppFragment = gql`
	fragment GetTheAppFields on PchWebGetTheApp {
		heading
		superHeadline
		superDescription
		iosLink {
			...CallToActionFields
		}
		androidLink {
			...CallToActionFields
		}
		leagueAsset {
			...DamAssetFields
		}
		getTheAppMaskAsset {
			...DamAssetFields
		}
		pchealthMobileMockup {
			...DamAssetFields
		}
	}
`;
