import { GetTheApp as GetTheAppImpl } from '@ldfeplatform/drx-component-library.ui.molecules.get-the-app';
import type { FunctionComponent } from 'react';
import type { GetTheAppFieldsFragment, Maybe } from '../contentful';
import { HeadingSubheadingComponent, type HeadingSpecsProps } from './common';
import { type SubHeadingSpecsProps } from './common/HeadingSubheadingComponent';

const HOMEPAGE_GTM_LINK_NAMES = {
	androidAppLinkName: 'google-play-store-download',
	iosAppLinkName: 'apple-app-store-download',
};

export type NormalizedLinkProps = {
	linkUrl: string;
	linkTarget: string;
	imageUrl: string;
	altText: string;
};

// TODO: Remove after March 14th, 2024 deployment
export type LinkToProcess = GetTheAppFieldsFragment['androidLink'] | GetTheAppFieldsFragment['iosLink'] | null;

function processLink(link?: LinkToProcess): NormalizedLinkProps {
	switch (link?.__typename) {
		case 'CallToAction': {
			return {
				linkUrl: link.linkUrl || '',
				linkTarget: link.target || '',
				imageUrl: link.image?.asset.url,
				altText: link.image?.altText || '',
			};
		}
	}

	return {
		linkUrl: '',
		linkTarget: '',
		imageUrl: '',
		altText: '',
	};
}

export type GetTheAppProps = {
	content: Maybe<GetTheAppFieldsFragment>;
};

export const GetTheApp: FunctionComponent<GetTheAppProps> = (props) => {
	const { content: getTheApp } = props;

	if (!getTheApp) {
		return <></>;
	}

	const iosLink = processLink(getTheApp.iosLink);
	const androidLink = processLink(getTheApp.androidLink);

	const headingData = {
		heading: getTheApp.superHeadline,
		subheading: getTheApp.superDescription,
	};

	const showHeadingSubheading = headingData?.heading || headingData?.subheading;

	const headSpecs: HeadingSpecsProps = {
		px: { base: 8, md: 0 },
		pt: { base: 0 },
		pb: { base: 4, md: 6 },
		mb: { base: '0.75em' },
		maxWidth: { base: '37.5em' },
		textAlign: { base: 'center' },
		margin: { base: '0 auto' },
	};

	const subHeadSpecs: SubHeadingSpecsProps = {
		pt: { base: 0 },
		pb: { base: 0 },
		textAlign: { base: 'center' },
	};

	return (
		<>
			{showHeadingSubheading && (
				<HeadingSubheadingComponent
					heading={headingData}
					center={true}
					headSpecs={headSpecs}
					subHeadSpecs={subHeadSpecs}
				/>
			)}
			<GetTheAppImpl
				title={getTheApp.heading || ''}
				iosLink={iosLink.linkUrl}
				iosLinkTarget={iosLink.linkTarget}
				iosAppStoreImageUrl={iosLink.imageUrl}
				iosAppStoreImageAltText={iosLink.altText}
				androidLink={androidLink.linkUrl}
				androidLinkTarget={androidLink.linkTarget}
				androidAppStoreImageUrl={androidLink.imageUrl}
				androidAppStoreImageAltText={androidLink.altText}
				leagueAssetImageUrl={getTheApp.leagueAsset?.asset?.url}
				leagueAssetImageAltText={getTheApp.leagueAsset?.altText || ''}
				pcHealthMobileMockupImageUrl={getTheApp.pchealthMobileMockup?.asset?.url}
				pcHealthMobileMockupImageAltText={getTheApp.pchealthMobileMockup?.altText || ''}
				getTheAppMaskImageUrl={getTheApp.getTheAppMaskAsset?.asset?.url}
				appAnalyticsData={HOMEPAGE_GTM_LINK_NAMES}
			/>
		</>
	);
};
