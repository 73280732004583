import { Box, Grid, theme } from '@chakra-ui/react';
import { IconTextComponent } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component';
import type { FunctionComponent } from 'react';
import type { HeadingSubheadingFieldsFragment, IconWithTextExtendedFieldsFragment, Maybe } from '../contentful';
import { asIconPosition, asIconVariant, asTextVariant } from '../contentful';
import { HeadingSubheadingComponent } from './common';

export type IconListWithHeadingComponentProps = {
	iconWithTextHeadingSubheading?: Maybe<HeadingSubheadingFieldsFragment>;
	iconWithTextCollection?: Maybe<Maybe<IconWithTextExtendedFieldsFragment>[]>;
};

export const IconListWithHeadingComponent: FunctionComponent<IconListWithHeadingComponentProps> = ({
	iconWithTextHeadingSubheading,
	iconWithTextCollection,
}) => {
	if (!iconWithTextCollection) {
		return <></>;
	}

	return (
		<>
			<Box data-testid="icon-list-with-heading" p={0} mt={{ base: 10, lg: '60px' }} textAlign="center">
				{iconWithTextHeadingSubheading && (
					<HeadingSubheadingComponent
						heading={iconWithTextHeadingSubheading}
						headSpecs={{
							pt: { base: 0 },
							pb: { base: 10, lg: 0 },
						}}
					/>
				)}

				<Grid
					bgColor={theme.colors.white}
					gridTemplateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
					m="auto"
					gridRowGap={0}
					gridColumnGap={8}
					justifyItems="center"
					pt={{ base: 0, lg: 4 }}
					pb={{ base: 0, lg: '44px' }}
					px={{ base: 0, lg: 32 }}
				>
					{iconWithTextCollection?.map(
						(item) =>
							item && (
								<Box key={item.text} maxW="352px" w="100%" h="auto" textAlign="center">
									<IconTextComponent
										imageSrc={item?.image?.asset?.url || ''}
										imageAlt={item?.image?.altText || ''}
										text={item.text || ''}
										subtext={item?.subtext || ''}
										iconPosition={asIconPosition(item?.iconPosition)}
										iconVariant={asIconVariant(item?.iconVariant)}
										textVariant={asTextVariant(item?.textVariant)}
									/>
								</Box>
							)
					)}
				</Grid>
			</Box>
		</>
	);
};
