import { gql } from '@apollo/client';

export const CallToActionFragment = gql`
	fragment CallToActionFields on CallToAction {
		label
		linkUrl
		target
		variant
		dataAnalytics
		image {
			...DamAssetFields
		}
		customAction
		customActionParameters
	}
`;
