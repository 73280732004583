import { Box, Container } from '@chakra-ui/react';
import { SimpleMasthead } from '@ldfeplatform/drx-component-library.ui.atoms.simple-masthead';
import { type ReactNode } from 'react';
import { simpleMastheadProps } from '../component';

export type SimpleMastheadTemplateProps = {
	children?: ReactNode;
};

export const SimpleMastheadTemplate = ({ children }: SimpleMastheadTemplateProps) => {
	return (
		<Container maxW={'1376px'} px={{ base: 8, md: 12 }} pt={8} pb={{ base: '3.25rem', md: 32 }}>
			<Box pb={{ base: 6, md: 8 }}>
				<SimpleMasthead {...simpleMastheadProps} />
			</Box>
			<Box w="100%">{children}</Box>
		</Container>
	);
};
