import { gql } from '@apollo/client';

export const BannerFragment = gql`
	fragment BannerFields on Banner {
		reference
		alternateText
		alternateLink {
			...CallToActionFields
		}
		title
		bannerPosition
		imagePosition
		imageAspectRatio16By9 {
			...DamAssetFields
		}
		imageAspectRatio1By1 {
			...DamAssetFields
		}
		backgroundColor
		heading
		descriptionV2
		actionRowsCollection(limit: 10) {
			items {
				actionRowCollection {
					items {
						... on CallToAction {
							...CallToActionFields
						}
					}
				}
			}
		}
	}
`;
