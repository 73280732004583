import { Box, Container, Grid, useTheme } from '@chakra-ui/react';
import type { CTAVariant, CallToAction } from '@ldfeplatform/drx-component-library.shared-types.types';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { RichTextRenderer } from '@ldfeplatform/drx-component-library.ui.atoms.rich-text-renderer';
import { IconTextComponent } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component';
import { PcidSection } from '@ldfeplatform/drx-component-library.ui.molecules.pcid-section';
import { TextWithButtonCtaComponent } from '@ldfeplatform/drx-component-library.ui.molecules.text-with-button-cta-component';
import { useRouter } from 'next/router';
import type { ParsedUrlQuery } from 'querystring';
import { useEffect, useState, type FunctionComponent } from 'react';
import { Banner, FAQComponent, HeadingSubheadingComponent, OnActionClick, OnActionClickDMR } from '../component';
import { IconListWithHeadingComponent } from '../component/IconListWithHeading';
import { ModalContent } from '../component/modals';
import type { LandingPageWithContent } from '../contentful';
import { asIconPosition, asIconVariant, asTextVariant, dmrPchSlug, dmrSdmSlug } from '../contentful';
import { updateGTMDataLayer } from '../hooks';
import type { StoreData } from '../services/customer-api';
import { normalizeRoute } from '../utilities/routing';
import { VAXCARE_ERROR_MODAL, VAXCARE_PICKUP_NOT_AVAILABLE_MODAL } from './homepageTemplate';
import { extractUtmParameters } from './templateHelpers';

export type LandingPageProps = {
	content: LandingPageWithContent;
	loading?: boolean;
};

const trackMetrics = (query: ParsedUrlQuery) => {
	if (!query.data || Array.isArray(query.data)) {
		return;
	}
	const qs = new URLSearchParams(atob(decodeURIComponent(query.data)));
	const source = qs.get('source');
	const utmParameters = extractUtmParameters(query);
	if (source === 'FASTPASS') {
		const registrationId = qs.get('registrationId');
		if (!registrationId) {
			// We want to confirm with Analytics what should happen if we visit this page without necessary parameters
			return;
		}
		updateGTMDataLayer({
			...utmParameters,
			registrationId,
		});
	} else if (Object.values(utmParameters).some((value) => value !== null && value !== undefined)) {
		updateGTMDataLayer({
			...utmParameters,
		});
	}
};

export const LandingPageTemplate: FunctionComponent<LandingPageProps> = ({ content, loading = false }) => {
	const router = useRouter();
	const theme = useTheme<Tokens>();
	const [storeData, setStoreData] = useState<StoreData>({});

	useEffect(() => {
		trackMetrics(router.query);
		if (normalizeRoute(router.query.slug) === 'vaxcare' && !router.query.key) {
			// This is the production link where we would redirect the customer to the direct shopper site
			router.push(
				`https://www.shoppersdrugmart.ca/${router.locale}/health-and-wellness/pharmacy-services/adult-vaccinations`
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query]);

	if (loading) {
		return <></>;
	}

	const {
		heroBannerListCollection,
		featureBanner,
		faq,
		iconWithTextHeadingSubheading,
		servicesIconWithTextHeadingSubheading,
		featureIconTextListCollection,
		bottomNavCardSection,
		bookAppointmentDescription,
		bookAppointmentHeading,
		bookAppointmentCta,
		servicesIconWithTextListCollection,
		references,
	} = content;

	const isModalOpen = router.query.appointmentAvailable === 'true' || router.query.pickupNotAvailable === 'true';

	const onClickBookAppointmentCta = () => {
		const { query } = router;
		const bookAppointmentUrl = bookAppointmentCta?.linkUrl || '';
		const slug = normalizeRoute(query.slug);
		if (slug === dmrPchSlug || slug === dmrSdmSlug) {
			OnActionClickDMR({
				baseUrl: bookAppointmentUrl,
				modifiedQuery: { ...query },
				router,
			});
		} else {
			OnActionClick({
				baseUrl: bookAppointmentUrl,
				modifiedQuery: { ...query },
				decodedData: atob(decodeURIComponent(query?.data as string)),
				router,
				redirectAction: bookAppointmentCta as CallToAction,
				setStore: setStoreData,
			});
		}
	};
	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				{heroBannerListCollection?.items[0] && (
					<Banner
						content={heroBannerListCollection?.items?.[0]}
						type="hero"
						loading={loading}
						setStore={setStoreData}
					/>
				)}
				<Banner content={featureBanner} type="feature" loading={loading} reverseOrder={false} />
				<Box data-testid="services-icon-list-with-heading" p={0} mt={{ base: 10, lg: '3.75rem' }} textAlign="center">
					{servicesIconWithTextHeadingSubheading && (
						<HeadingSubheadingComponent
							heading={servicesIconWithTextHeadingSubheading}
							headSpecs={{
								pt: { base: 0 },
								pb: { base: 10, lg: 0 },
							}}
						/>
					)}
				</Box>
				{servicesIconWithTextListCollection && (
					<Grid
						bgColor={theme.colors.white}
						gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
						m="auto"
						gridRowGap={0}
						gridColumnGap={0}
						justifyItems="center"
					>
						{servicesIconWithTextListCollection.items?.map(
							(item) =>
								item && (
									<Box key={item.text} py={{ base: 10, lg: 14 }} px={{ base: 8, lg: 32 }} w="100%">
										<IconTextComponent
											imageSrc={item?.image?.asset?.url || ''}
											imageAlt={item?.image?.altText || ''}
											subtext={item?.subtext || ''}
											text={item.text || ''}
											iconPosition={asIconPosition(item?.iconPosition)}
											iconVariant={asIconVariant(item?.iconVariant)}
											textVariant={asTextVariant(item?.textVariant)}
										/>
									</Box>
								)
						)}
					</Grid>
				)}
				<IconListWithHeadingComponent
					iconWithTextHeadingSubheading={iconWithTextHeadingSubheading}
					iconWithTextCollection={featureIconTextListCollection?.items}
				/>
				<FAQComponent faq={faq} />
				{bottomNavCardSection && (
					<Box
						mx={{ base: 6, md: 'auto' }}
						mt={{ base: 0, md: 20 }}
						mb={{ base: 20, lg: 40 }}
						minW="327px"
						maxW="600px"
						data-testid="PcidSection"
					>
						<PcidSection
							icon={{
								asset: {
									url: bottomNavCardSection.image?.asset?.url || '',
								},

								altText: bottomNavCardSection.image?.altText || '',
							}}
							heading={bottomNavCardSection.headline || ''}
							description={bottomNavCardSection.bodyText || ''}
							cta={
								{
									label: bottomNavCardSection.cta?.label || '',
									variant: (bottomNavCardSection.cta?.variant as CTAVariant) || 'tertiary',
									linkUrl: bottomNavCardSection.cta?.linkUrl || '',
									target: bottomNavCardSection.cta?.target || '',
								} as CallToAction
							}
						/>
					</Box>
				)}
				{references && (
					<Box py={10} px={8}>
						<RichTextRenderer content={references.json} />
					</Box>
				)}
				{bookAppointmentCta && bookAppointmentHeading && (
					<div data-testid="bookAppointment">
						<TextWithButtonCtaComponent
							headingText={bookAppointmentHeading}
							cta={{
								variant: bookAppointmentCta.variant || '',
								label: bookAppointmentCta.label || '',
								dataAnalytics: bookAppointmentCta.dataAnalytics || '',
							}}
							description={bookAppointmentDescription || ''}
							onActionClick={onClickBookAppointmentCta}
						/>
					</div>
				)}
				<ModalContent
					isOpen={isModalOpen}
					onClose={async () => {
						const replaceString = router.asPath.includes('&appointmentAvailable=true')
							? '&appointmentAvailable=true'
							: '&pickupNotAvailable=true';
						await router.replace(router.asPath.replace(replaceString, ''), undefined, { shallow: true });
					}}
					identifier={
						router.asPath.includes('&appointmentAvailable=true')
							? VAXCARE_ERROR_MODAL
							: VAXCARE_PICKUP_NOT_AVAILABLE_MODAL
					}
					storeData={storeData}
				/>
			</Container>
		</>
	);
};
