import { createFactory } from '@withease/factories';
import { createEffect, createEvent, sample, type EventCallable } from 'effector';

import { type ZodError, type ZodSchema } from 'zod';

import type { ToastOptions } from '../toast';

export type FactoryParams<TPayload> = {
	targetEvent: EventCallable<TPayload>;
	contract: ZodSchema<TPayload>;
	showToast: EventCallable<ToastOptions>;
};

type Creator<T> = (params: FactoryParams<T>) => EventCallable<unknown>;

export const CustomActionFactory = <T>(params: FactoryParams<T>) =>
	createFactory<Creator<T>>((params) => {
		const { contract, targetEvent, showToast } = params;

		const validateContractFx = createEffect<unknown, T, ZodError>(contract.parse);

		sample({
			clock: validateContractFx.doneData,
			target: targetEvent,
		});

		sample({
			clock: validateContractFx.fail,
			fn({ error }) {
				// convert validation error into a toast message
				return { isSuccess: false, message: error.message };
			},
			target: showToast,
		});

		const startValidation = createEvent<unknown>();

		sample({
			clock: startValidation,
			target: validateContractFx,
		});

		return startValidation;
	})(params);
