import { getCookie } from 'cookies-next';
import { useEffect, useRef, useState } from 'react';

export type ConsentGroup = {
	[key: string]: number;
	C0002: number;
	C0003: number;
	C0004: number;
};

export type OneTrust = {
	getGeolocationData: () => void;
};

export const useConsent = () => {
	const [consentGroups, setConsentGroups] = useState<ConsentGroup>({ C0002: 0, C0003: 0, C0004: 0 });
	const [isConsentSelected, setIsConsentSelected] = useState(false);
	const [isLocationQC, setIsLocationQC] = useState(false);

	const parseGroups = (inputString: string): ConsentGroup => {
		const keyValuePairs = inputString.split(',').map((pair) => pair.split(':'));
		const resultObject: ConsentGroup = {
			C0002: 0,
			C0003: 0,
			C0004: 0,
		};
		keyValuePairs.forEach(([key, value]) => {
			resultObject[key] = parseInt(value, 10);
		});

		return resultObject;
	};

	const getGeoLocation = () => {
		if (window && window.OneTrust && (window.OneTrust as OneTrust).getGeolocationData) {
			const location: any = (window.OneTrust as OneTrust).getGeolocationData();
			const country = location.country.toLowerCase();
			const state = location.state.toLowerCase();
			if (country === 'ca' && state === 'qc' && isLocationQC === false) {
				setIsLocationQC(true);
			}
		}
	};

	const refreshConsent = () => {
		const consentCookie = getCookie('OptanonConsent');
		if (consentCookie) {
			const groupsMatch = consentCookie.match(/groups=([^&;]*)/);

			getGeoLocation();

			if (groupsMatch) {
				const groupsString = groupsMatch[1];
				const formattedGroups = parseGroups(groupsString);
				setIsConsentSelected(true);
				setConsentGroups(formattedGroups);
			}
		}
	};

	const ref = useRef(false);
	useEffect(() => {
		// ! Prevent this effect from double-firing on page load
		if (!ref.current) {
			ref.current = true;
			return;
		}

		refreshConsent();
		window.addEventListener('oneTrustCategoriesChanged', refreshConsent);

		return () => {
			window.removeEventListener('oneTrustCategoriesChanged', refreshConsent);
		};

		// ! Keep this empty array here or you will experience a memory leak
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLocationQC: isLocationQC,
		isConsentSelected: isConsentSelected,
		isPerformanceEnabled: consentGroups?.C0002 === 1,
		isFunctionalEnabled: consentGroups?.C0003 === 1,
		isTargettingEnabled: consentGroups?.C0004 === 1,
	};
};
