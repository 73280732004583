import { type ToastId, useToast as useChakraToast } from '@chakra-ui/react';
import { Toast as CustomToast } from '@ldfeplatform/drx-component-library.ui.molecules.toast';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export type ToastRefs = {
	showToast: (isSuccess: boolean, message: string, description?: string) => void;
};

/**
 * @deprecated deprecated in favor of useToast hook
 */
export const ToastComponent = forwardRef<ToastRefs, {}>((_, ref) => {
	const toast = useChakraToast();
	const toastIdRef = useRef<ToastId>(0);
	const toastIdsRef = useRef<ToastId[]>([]);

	useImperativeHandle(ref, () => ({
		showToast: (isSuccess: boolean, message: string, description = '') => {
			// NB: When the user is updating the marketing notifications, they are able to check / uncheck rapidly which causes an overflow of toasts.  This is to prevent more than 2 appearing at a time.
			if (toastIdsRef.current.length === 2) {
				toast.close(toastIdsRef.current[0]);
				toastIdsRef.current = toastIdsRef.current.slice(1);
			}

			const toastId = toast({
				duration: 9000,
				render: () => (
					<CustomToast
						status={isSuccess ? 'success' : 'error'}
						title={message}
						description={description}
						onToastClose={() => {
							if (toastIdRef.current) {
								toast.close(toastIdRef.current);
							}
						}}
					/>
				),
			});

			toastIdRef.current = toastId;
			toastIdsRef.current.push(toastId);
		},
	}));

	return null;
});
