import { gql } from '@apollo/client';

export const NavCardFragment = gql`
	fragment NavCardFields on NavCard {
		image {
			...DamAssetFields
		}
		cta {
			...CallToActionFields
		}
		headline
		bodyText
	}
`;
