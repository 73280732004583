import { gql } from '@apollo/client';

export const NavigationItemFragment = gql`
	fragment NavigationItemFields on NavigationItem {
		sectionSlot
		link
		opensInANewWindow
		ordinal
		icon
		label
		cssClass
		returnLabel
		childrenCollection(
			limit: 10
			where: {
				AND: [
					{ OR: [{ restrictionBannerCollection_exists: false }, { restrictionBanner: { banner: $banner } }] }
					{ OR: [{ restrictionRegionCollection_exists: false }, { restrictionRegion: { region: $region } }] }
				]
			}
		) {
			items {
				link
				opensInANewWindow
				ordinal
				icon
				label
				cssClass
				returnLabel
				dataAnalytics
			}
		}
		dataAnalytics
	}
`;
