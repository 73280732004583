import { getSDUINavCardProps } from '@/features/customActions/customActionHandler';
import { Box, Container, useTheme } from '@chakra-ui/react';
import { type NavCardData } from '@ldfeplatform/drx-component-library.ui.molecules.nav-card';
import { NavCardList, type NavCardListProps } from '@ldfeplatform/drx-component-library.ui.organisms.nav-card-list';
import type { FunctionComponent } from 'react';
import type {
	FeatureCardFieldsFragment,
	HeadingSubheadingFieldsFragment,
	Maybe,
	NavCardFieldsFragment,
} from '../contentful';
import { HeadingSubheadingComponent } from './common';

export type NavCardComponentProps = {
	cardListLayout: NavCardListProps['cardListLayout'];
	cardContentLayout: NavCardListProps['cardContentLayout'];
	items?: Maybe<NavCardFieldsFragment | FeatureCardFieldsFragment>[];
	heading?: Maybe<HeadingSubheadingFieldsFragment>;
	section?: string;
};

export const NavCardComponent: FunctionComponent<NavCardComponentProps> = ({
	cardListLayout,
	cardContentLayout,
	items,
	heading,
	section,
}) => {
	const theme = useTheme();
	if (!items?.length) {
		return;
	}
	const headSpecs = {
		pt: { base: 10, md: section === 'HomepageTemplate-Auth-CardList' ? 16 : 40 },
		pb: { base: 4 },
	};
	const subHeadSpecs = {
		pb: { base: 0 },
	};

	return (
		<Container maxW={theme.breakpoints['2xl']} p={0}>
			{heading && <HeadingSubheadingComponent heading={heading} headSpecs={headSpecs} subHeadSpecs={subHeadSpecs} />}
			{renderNavigationCards(cardListLayout, cardContentLayout, items)}
		</Container>
	);
};

const parseNavData = (cards?: Maybe<NavCardFieldsFragment | FeatureCardFieldsFragment>[]): NavCardData[] => {
	if (!cards) {
		return [];
	}

	return cards
		.filter(<T,>(x: T): x is NonNullable<T> => !!x)
		.map((item) => {
			return getSDUINavCardProps(item);
		});
};

function renderNavigationCards(
	cardListLayout: NavCardListProps['cardListLayout'],
	cardContentLayout: NavCardListProps['cardContentLayout'],
	items?: Maybe<NavCardFieldsFragment | FeatureCardFieldsFragment>[]
) {
	const navCardDataList = parseNavData(items);

	return (
		<Box
			pt={10}
			pb={{
				base: 10,
				lg: 16,
			}}
			px={{
				base: 8,
				'2xl': 32,
			}}
			justifyItems="center"
		>
			<NavCardList
				navCardDataList={navCardDataList}
				cardListLayout={cardListLayout}
				cardContentLayout={cardContentLayout}
			/>
		</Box>
	);
}
