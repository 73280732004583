import { gql } from '@apollo/client';

export const MastheadFragment = gql`
	fragment MastheadFields on MastHead {
		icon {
			...CallToActionFields
		}
		navigationsCollection(
			limit: 10
			where: {
				AND: [
					{ OR: [{ restrictionBannerCollection_exists: false }, { restrictionBanner: { banner: $banner } }] }
					{ OR: [{ restrictionRegionCollection_exists: false }, { restrictionRegion: { region: $region } }] }
				]
			}
		) {
			total
			items {
				... on NavigationItem {
					...NavigationItemFields
				}
			}
		}
		promoRibbonCollection(
			limit: 1
			where: {
				AND: [
					{ OR: [{ restrictionListBannerCollection_exists: false }, { restrictionListBanner: { banner: $banner } }] }
					{ OR: [{ restrictionListRegionCollection_exists: false }, { restrictionListRegion: { region: $region } }] }
				]
			}
			order: [title_ASC]
		) {
			items {
				context {
					json
				}
			}
		}
		mastHeadCtasCollection(limit: 5) {
			items {
				...CallToActionFields
			}
		}
		variant
	}
`;
