import { invoke } from '@withease/factories';
import { GiveConsentPayloadContract } from '../consent/contracts';
import { ConsentManager } from '../consent/model';
import { CustomActionFactory } from '../customActions/customAction';
import { showToast } from '../toast';

export const giveConsentCustomAction = invoke(() =>
	CustomActionFactory({
		targetEvent: ConsentManager.consentAccepted,
		contract: GiveConsentPayloadContract,
		showToast,
	})
);
