import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export type Locale = 'en' | 'fr';
export type LanguageCode = 'eng' | 'fra';
export type LocaleCode = 'en-CA' | 'fr-CA';

const getLanguage = (locale: string | undefined) => (locale == 'fr' ? locale : 'en');

export const useLanguage = (): {
	localeCode: LocaleCode;
	langCode: LanguageCode;
	locale: Locale;
	isFrench: boolean;
} => {
	const router = useRouter();
	const [locale, setLang] = useState<Locale>(getLanguage(router.locale));
	useEffect(() => {
		setLang(getLanguage(router.locale));
	}, [router.locale]);
	const isFrench = locale === 'fr';
	return {
		locale,
		langCode: isFrench ? 'fra' : 'eng',
		localeCode: isFrench ? 'fr-CA' : 'en-CA',
		isFrench,
	};
};
