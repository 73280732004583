import { z } from 'zod';

export const AbandonMessageContract = z.object({ event: z.literal('abandon') });
export type AbandonMessage = z.infer<typeof AbandonMessageContract>;
export const CloseMessageContract = z.object({ event: z.literal('close') });
export type CloseMessage = z.infer<typeof CloseMessageContract>;
export const EnterMessageContract = z.object({ event: z.literal('enter') });
export type EnterMessage = z.infer<typeof EnterMessageContract>;
export const ErrorMessageContract = z.object({ event: z.literal('error'), details: z.string().optional() });
export type ErrorMessage = z.infer<typeof ErrorMessageContract>;
export const SuccessMessageContract = z.object({
	event: z.literal('success'),
	appointmentId: z.string(),
	province: z.string(),
	type: z.string(),
	eventType: z.union([z.literal('booking'), z.literal('walkin')]),
});

export type SuccessMessage = z.infer<typeof SuccessMessageContract>;

export const IframeMessageContract = z.discriminatedUnion('event', [
	AbandonMessageContract,
	CloseMessageContract,
	EnterMessageContract,
	ErrorMessageContract,
	SuccessMessageContract,
]);

export type IFrameEvent = z.infer<typeof IframeMessageContract>;
