import { useSessionContext } from '@/shared/clientSession';
import { useLanguage } from '@/shared/useLanguage';
import { Box, Container, Grid, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { PageLoader } from '@ldfeplatform/drx-component-library.ui.atoms.page-loader';
import { IconTextComponent } from '@ldfeplatform/drx-component-library.ui.molecules.icon-text-component';
import { useRouter } from 'next/router';
import { useEffect, useRef, type FunctionComponent } from 'react';
import {
	Banner,
	CardComponent,
	GetTheApp,
	NavCardComponent,
	Notice,
	PharmacyListComponent,
	ToastComponent,
	type ToastRefs,
} from '../component';
import { ModalContent } from '../component/modals';
import type { HomepageWithContent } from '../contentful';
import { asIconVariant, asTextVariant } from '../contentful';
import { useProfileContext } from '../context';
import { getQueryString } from '../utilities/formatting';

export type HomepageTemplateProps = {
	content: HomepageWithContent;
	loading?: boolean;
};

export type ModalIdentifer =
	| 'account-creation-success-modal'
	| 'vaxcare-error-modal'
	| 'vaxcare-pickup-not-available-modal'
	| 'claim-used-modal'
	| 'claim-found-modal'
	| 'claim-none-modal'
	| string;

export const ACCOUNT_CREATION_SUCCESS_MODAL = 'account-creation-success-modal';
export const VAXCARE_ERROR_MODAL = 'vaxcare-error-modal';
export const VAXCARE_PICKUP_NOT_AVAILABLE_MODAL = 'vaxcare-pickup-not-available-modal';
export const CLAIM_USED_MODAL = 'claim-used-modal';
export const CLAIM_FOUND_MODAL = 'claim-found-modal';
export const CLAIM_NONE_MODAL = 'claim-none-modal';
export const modalIdentifierList: ModalIdentifer[] = [
	ACCOUNT_CREATION_SUCCESS_MODAL,
	VAXCARE_ERROR_MODAL,
	VAXCARE_PICKUP_NOT_AVAILABLE_MODAL,
	CLAIM_USED_MODAL,
	CLAIM_FOUND_MODAL,
	CLAIM_NONE_MODAL,
];

export const isValidModalIdentifier = (list: string | string[], value: string | string[]): value is ModalIdentifer => {
	return typeof value === 'string' && list.includes(value);
};

export const HomepageTemplate: FunctionComponent<HomepageTemplateProps> = ({ content, loading = false }) => {
	const router = useRouter();
	const { modal = '', toastsuccess, toasttitle, toastdescription } = router.query;
	const { status } = useSessionContext();

	const modalIdentifier: ModalIdentifer = isValidModalIdentifier(modalIdentifierList, modal) ? modal : '';

	const theme = useTheme<Tokens>();
	const { accountStatus, account } = useProfileContext();
	const markedToDelete = account?.markedToDelete;
	const { locale, isFrench } = useLanguage();

	const cardListLayout = 'row';

	const cardContentLayout = 'column';

	const toastRef = useRef<ToastRefs>(null);

	const toastSuccess = toastsuccess === 'true';
	const toastTitle = getQueryString(toasttitle);
	const toastTitleDecoded = decodeURIComponent(toastTitle);

	const toastDescription = getQueryString(toastdescription);
	const toastDescriptionDecoded = decodeURIComponent(toastDescription);

	const isPageLoading = loading;
	const accountIsFetching = accountStatus === 'fetching';
	const accountHasNoProfile = accountStatus === 'no-profile';
	const fetchingOrNoProfile = accountIsFetching || accountHasNoProfile;
	const isAccountStatusAvailable = status === 'authenticated' && fetchingOrNoProfile;
	const isLoading = isPageLoading || isAccountStatusAvailable;

	useEffect(() => {
		if (markedToDelete) {
			router.push(`/${locale}/deletePending`);
			return;
		}

		// redirect user to createProfile page only when user is authenticated and has no pchProfile
		// on getAccount API error, user will go to homepage
		// TO DO: Jeremy Fix this redirecting
		// if (status === 'authenticated' && accountStatus === 'no-profile') {
		// 	router.replace('/createProfile');
		// }
	}, [accountStatus, status, router, markedToDelete, locale]);

	useEffect(() => {
		if (toastTitle && !isLoading) {
			toastRef.current?.showToast(toastSuccess, toastTitleDecoded, toastDescriptionDecoded);
		}
	}, [toastSuccess, toasttitle, toastdescription, toastTitleDecoded, toastDescriptionDecoded, isLoading, toastTitle]);

	if (isLoading) {
		return <PageLoader />;
	}

	if (content?.__typename !== 'HomepageTemplate') {
		return <Notice text="Invalid content returned from CMS" />;
	}

	const {
		heroBannerListCollection,
		featureCardsCollection,
		sectionBanner1ListCollection,
		staticCardsCollection,
		logoList,
		sectionBanner2ListCollection,
		navigationCardsCollection,
		getTheAppListCollection,
		featureCardList1,
		featureCardList2,
		featureCardList3,
	} = content;

	const renderStaticCardCollection = () => {
		return staticCardsCollection?.items.map((item) => {
			const key = item?.text;
			const icon = item?.icon || '';
			const text = item?.text || '';
			const iconVariant = asIconVariant(item?.iconVariant);
			const textVariant = asTextVariant(item?.textVariant);
			return (
				<Box key={key} w="100%" textAlign="center">
					<IconTextComponent
						icon={icon}
						text={text}
						iconPosition="top"
						iconVariant={iconVariant}
						textVariant={textVariant}
					/>
				</Box>
			);
		});
	};

	return (
		<>
			<Container maxW={theme.breakpoints['2xl']} p={0}>
				{heroBannerListCollection?.items[0] && (
					<Banner content={heroBannerListCollection?.items[0]} type="hero" loading={loading} />
				)}

				{status === 'authenticated' ? (
					<>
						<NavCardComponent
							heading={{
								heading: featureCardList1?.heading ?? '',
								subheading: featureCardList1?.subheading ?? '',
							}}
							items={featureCardList1?.cardsCollection?.items}
							cardContentLayout={cardContentLayout}
							cardListLayout={cardListLayout}
							section={`${content?.__typename}-Auth-${featureCardList1?.__typename}`}
						/>
						<NavCardComponent
							heading={{
								heading: featureCardList2?.heading ?? '',
								subheading: featureCardList2?.subheading ?? '',
							}}
							items={featureCardList2?.cardsCollection?.items}
							cardContentLayout={cardContentLayout}
							cardListLayout={cardListLayout}
							section={`${content?.__typename}-Auth-${featureCardList1?.__typename}`}
						/>
						<NavCardComponent
							heading={{
								heading: featureCardList3?.heading ?? '',
								subheading: featureCardList3?.subheading ?? '',
							}}
							items={featureCardList3?.cardsCollection?.items}
							cardContentLayout={cardContentLayout}
							cardListLayout={cardListLayout}
							section={`${content?.__typename}-Auth-${featureCardList1?.__typename}`}
						/>
					</>
				) : (
					<>
						<CardComponent cards={featureCardsCollection?.items} />
						{sectionBanner1ListCollection?.items[0] && (
							<Banner content={sectionBanner1ListCollection.items[0]} type="feature" loading={loading} />
						)}
						<Grid
							bgColor={theme.colors.pcBlueLight}
							gridTemplateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
							pt={{ base: 10, lg: 20 }}
							pb={{ base: 10, lg: 8 }}
							px={{ base: 8, '2xl': 32 }}
							gap={{ base: 8 }}
							justifyItems="center"
						>
							{renderStaticCardCollection()}
						</Grid>
						<PharmacyListComponent logoGrid={logoList} transparentBackground={true} />
						{sectionBanner2ListCollection?.items[0] && (
							<Banner content={sectionBanner2ListCollection.items[0]} type="feature" loading={loading} />
						)}
						<NavCardComponent
							cardListLayout={cardListLayout}
							cardContentLayout={cardContentLayout}
							items={navigationCardsCollection?.items}
						/>
					</>
				)}
			</Container>
			{getTheAppListCollection?.items[0] && <GetTheApp content={getTheAppListCollection?.items[0]} />}
			{modalIdentifier && (
				<ModalContent
					isOpen={Boolean(modal)}
					ctaOrientation="horizontal"
					onClose={async () => await router.replace('/', undefined, { shallow: true })}
					identifier={modalIdentifier}
				/>
			)}
			<ToastComponent ref={toastRef} />
		</>
	);
};
