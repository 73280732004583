import { Box, Button, Text, useTheme } from '@chakra-ui/react';
import { type Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { AccordionList } from '@ldfeplatform/drx-component-library.ui.molecules.accordion-list';
import type { FunctionComponent } from 'react';
import type { FaqFieldsFragment, HeadingSubheadingFieldsFragment, Maybe } from '../contentful';
import { HeadingSubheadingComponent } from './common';
import { type HeadingSpecsProps, type SubHeadingSpecsProps } from './common/HeadingSubheadingComponent';

export type FAQComponentProps = {
	faq: Maybe<FaqFieldsFragment>;
	heading?: Maybe<HeadingSubheadingFieldsFragment>;
};

export const FAQComponent: FunctionComponent<FAQComponentProps> = ({ faq, heading }) => {
	const theme = useTheme<Tokens>();
	if (!faq) {
		return <></>;
	}

	const { title, faqCta } = faq;
	const accordionListItems =
		faq.accordionListCollection?.items?.map((item) => ({
			heading: item?.heading ?? '',
			body: item?.body ?? '',
			imageData: {
				src: item?.image?.asset?.url ?? '',
				alt: item?.image?.altText ?? '',
			},
		})) ?? [];

	const headSpecs: HeadingSpecsProps = {
		pt: { base: 0 },
		pb: { base: 0 },
		textAlign: { base: 'center' },
	};
	const subHeadSpecs: SubHeadingSpecsProps = {
		pt: { base: 6 },
		pb: { base: 0 },
		textAlign: { base: 'center' },
	};

	return (
		<Box data-testid="faq-component" mx="auto" maxWidth={600} py={20} px={{ base: 4, md: 8 }}>
			{heading ? (
				<HeadingSubheadingComponent heading={heading} headSpecs={headSpecs} subHeadSpecs={subHeadSpecs} />
			) : (
				<Text {...theme.faqComponent.faqHeading} mb={8} textAlign="center">
					{title}
				</Text>
			)}
			<Box my={16}>
				<AccordionList accordionList={accordionListItems} showImage={false} />
			</Box>
			{faqCta && (
				<Box textAlign="center" mt={8}>
					<Button
						as="a"
						size={{ base: 'sm', md: 'lg' }}
						w={{ base: '137px', sm: 'auto' }}
						href={faqCta?.linkUrl ?? ''}
						variant={convertVariant(faqCta?.variant)}
					>
						{faqCta?.label}
					</Button>
				</Box>
			)}
		</Box>
	);
};

function convertVariant(variant?: Maybe<string>) {
	switch (variant) {
		case 'primary':
			return 'primary';
		case 'secondary':
		default:
			return 'secondary';
		// TODO: Fix the component to expose the correct types
		// case 'tertiary':
		// 	return 'tertiary';
	}
}
