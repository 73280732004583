import { upsertSalesforceRecord } from '@/entities/salesforce';
import type { PostFetchTokenResponseType } from '@pcid/web-sdk';
import type { PCHUserResponse, UpdateUserProfile, UserProfile } from '../../../services/midtier/user/__generated';

export const patchUser = async (accountPayload: UpdateUserProfile): Promise<PCHUserResponse> => {
	const response = await fetch('/api/profile/updateProfile/', {
		method: 'PATCH',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify(accountPayload),
	});
	if (response.ok) {
		const profile: PCHUserResponse = await response.json();

		return profile;
	}
	throw new Error(await response.text());
};

export const getUser = async () => {
	const response = await fetch('/api/profile/getProfile/', {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	});
	if (response.status === 404) {
		return undefined;
	}

	if (response.status === 200) {
		const payload: PCHUserResponse = await response.json();
		return payload;
	}

	throw new Error(await response.text());
};

export const createUserProfile = async (payload: UserProfile) =>
	await fetch('/api/profile/createProfile/', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
		body: JSON.stringify(payload),
	});

export const deleteUser = async () => {
	const response = await fetch('/api/profile/deleteProfile/', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
		},
	});

	if (response.status === 204) {
		const payload = { softDeleteTimestamp: new Date() };
		await upsertSalesforceRecord(payload);
	} else {
		console.error('error', response.status);
	}
};

export const deleteSession = async () => {
	const response = await fetch('/api/auth/updateToken/', {
		method: 'delete',
		headers: { 'content-type': 'application/json' },
	});

	if (response.ok) {
		const data: { status: string } = await response.json();
		return data;
	}
	throw new Error('Failed to clean up session');
};

export const createSession = async (token: PostFetchTokenResponseType) => {
	const response = await fetch('/api/auth/updateToken/', {
		method: 'post',
		body: JSON.stringify(token),
		headers: { 'content-type': 'application/json' },
	});
	if (response.ok) {
		const data: { status: 'ok' } | { status: 'error'; message: unknown } = await response.json();

		if (data.status === 'ok') {
			return data;
		}
		throw new Error(String(data.message));
	}
	throw new Error('Failed to set up session');
};

export function userFound(user: PCHUserResponse | undefined): user is PCHUserResponse {
	return !!user;
}

export function userNotFound(user: PCHUserResponse | undefined): user is undefined {
	return !user;
}
