import { gql } from '@apollo/client';

export const SeoSettingsFragment = gql`
	fragment SeoSettingsFields on PchSeoTags {
		title
		description
		canonicalUrl
		metaRobotsIndex
		crawlerFollow
		additionalLinkTags
		additionalMetaTags
		openGraphTwitterTitle
		openGraphTwitterDescription
		openGraphTwitterImageReference {
			...DamAssetFields
		}
		openGraphType
		openGraphTwitterUrl
		twitterCard
		twitterSite
	}
`;
