import { AccountManager, accountDataAvailable } from '@/entities/account';
import { $locale } from '@/features/application';
import { BookingIframeManager } from '@/features/bookingIframe/model';
import { ConsentManager } from '@/features/consent/model';
import { onModalHidden, showModal, type ShowModalEventArgs } from '@/features/modal/model';
import { createEvent, createStore, sample } from 'effector';
import { debug } from 'patronum';
import { $region } from '../../context/GeolocationContext';
import type { StartAptBookingParams } from './contracts';

export const startAppointmentBooking = createEvent<StartAptBookingParams>();

export const $isInFlow = createStore<StartAptBookingParams | undefined>(undefined, { skipVoid: false });

sample({
	clock: startAppointmentBooking,
	source: $isInFlow,
	filter: (src) => !src,
	fn: (_, clk) => clk,
	target: [$isInFlow, ConsentManager.requestConsentCheck.prepend((evt: StartAptBookingParams) => evt.partnerName)],
});

sample({
	clock: ConsentManager.consentCheckCompleted,
	source: [$isInFlow, $region, $locale] as const,
	filter: ([inFlow]) => !!inFlow, // inFlow.partnerId === consent.parentId
	fn: ([inFlow, province, locale], { partnerId }) => ({
		partnerId,
		specialtyId: inFlow?.specialtyId,
		serviceId: inFlow?.serviceId,
		locale,
		province,
	}),
	target: BookingIframeManager.getAuthenticatedUrl,
});

// Sample to handle medme iframe success
sample({
	clock: BookingIframeManager.flowSucceeded,
	source: AccountManager.$account,
	filter: accountDataAvailable,
	fn: (account, booking): ShowModalEventArgs => {
		// parameters for the modal
		const identifier = 'booking-medme-success-modal';
		const templateVariables = {
			displayName: account.profile.preferredFirstName || account.profile.firstName || '',
			...booking,
		};

		return {
			identifier,
			templateProps: {},
			templateVariables,
		};
	},
	target: showModal,
});

sample({
	clock: onModalHidden, // When modal gets hidden
	source: $isInFlow, // We check if we are currently in the booking flow
	filter: (inFlow) => !!inFlow, // If so,
	fn: () => undefined, // reset $inFlow with an empty value
	target: $isInFlow,
});

$isInFlow.reset(
	BookingIframeManager.flowSucceeded,
	BookingIframeManager.flowAbandoned,
	BookingIframeManager.flowClosed,
	BookingIframeManager.flowErrored
);

debug({ trace: true }, { $isInFlow });
