import { Box, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { PharmacyList } from '@ldfeplatform/drx-component-library.ui.organisms.pharmacy-list';
import { EventName as EventNameEnum } from '@sdm/analytics-helper-library-pchweb';
import type { FunctionComponent } from 'react';
import type { LogoListFieldsFragment, Maybe } from '../contentful';
import { BANNER, CLICK, CTA_CLICK, useSnowplow, VIEW_LESS, VIEW_MORE } from '../context';

export type PharmacyListProps = {
	logoGrid: Maybe<LogoListFieldsFragment>;
	transparentBackground?: boolean;
};

export const PharmacyListComponent: FunctionComponent<PharmacyListProps> = ({
	logoGrid,
	transparentBackground = false,
}) => {
	const theme = useTheme<Tokens>();
	const { track } = useSnowplow();

	if (!logoGrid) {
		return <></>;
	}

	const brandLogos = logoGrid.logosCollection?.items.map((logo) => ({
		url: logo?.asset?.url,
		altText: logo?.altText ?? '',
	}));

	function recordMetrics(clickElement: string) {
		track(EventNameEnum.UiEngagement, {
			component: {
				name: logoGrid?.__typename || null,
				id: logoGrid?.sys.id || null,
				type: BANNER,
				click_element: clickElement,
				click_action: CTA_CLICK,
			},
			custom_engagement: {
				action: CLICK,
			},
		});
	}

	const handleOnToggle = (isOpen: boolean) => {
		const clickElement = isOpen ? VIEW_MORE : VIEW_LESS;
		recordMetrics(clickElement);
	};

	return (
		<Box
			bgColor={transparentBackground ? theme.colors.pcBlueLight : 'transparent'}
			pb={{ base: 16, md: 20 }}
			px={{ base: 8, '2xl': 32 }}
		>
			<PharmacyList
				heading={logoGrid.heading ?? ''}
				subheading={logoGrid.subHeading ?? ''}
				logos={brandLogos ?? []}
				logosAboveTheFold={logoGrid.logosAboveFold ?? 4}
				collapseLabel={logoGrid.collapseLabel ?? ''}
				expandLabel={logoGrid.expandLabel ?? ''}
				disclaimers={logoGrid.disclaimers ?? ''}
				onToggle={handleOnToggle}
			/>
		</Box>
	);
};
