import { gql } from '@apollo/client';

export const AccordionFragment = gql`
	fragment AccordionFields on Accordion {
		image {
			...DamAssetFields
		}
		heading
		body
	}
`;
