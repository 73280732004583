import { gql } from '@apollo/client';

export const ArticlePageTemplateFields = gql`
	fragment PchArticleTemplateFields on PchArticleTemplate {
		__typename
		sys {
			id
		}
		featureCardsCollection {
			items {
				...FeatureCardFields
			}
		}
		breadcrumbsCtAsCollection(limit: 5) {
			items {
				callToActionListCollection {
					items {
						...CallToActionFields
					}
				}
			}
		}
		articleHeadingSubheading {
			...HeadingSubheadingFields
		}
		paragraphsCollection {
			items {
				context {
					json
				}
			}
		}
	}
`;
