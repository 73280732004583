import { gql } from '@apollo/client';

export const BannerWithAccordionFields = gql`
	fragment BannerWithAccordionFields on BannerWithAccordion {
		bannerWithAccordionHeading {
			...HeadingSubheadingFields
		}
		bannerWithAccordionCtAsCollection(
			limit: 1
			where: {
				AND: [
					{ OR: [{ restrictionListBannerCollection_exists: false }, { restrictionListBanner: { banner: $banner } }] }
					{ OR: [{ restrictionListRegionCollection_exists: false }, { restrictionListRegion: { region: $region } }] }
				]
			}
		) {
			items {
				...CallToActionListFields
			}
		}
		bannerWithAccordionAccordionCollection {
			items {
				...AccordionFields
			}
		}
		backgroundColor
	}
`;
